const routes = {
  dashboard: `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/dashboard/`,
  products: `/products/`,
  audience: `/audience/`,
  newsletterSettings: `/newsletter-settings/`,
  createCampaign: '/newsletter/create/',
  editCampaign: (newsletterId, step) =>
    `/newsletter/edit/${newsletterId}/?step=${step}`,
  myFeeds: `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/feed/`,
  createPodcast: `/products/create/`,
  addAudience: `/audience/import/`,
  podcastPage: (productSlug) =>
    `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/podcasts/${productSlug}`,
  donationPage: (productSlug) =>
    `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/donation/${productSlug}`,
  podcastEpisodesPage: (podcastSlug) => `/podcasts/${podcastSlug}/episodes`,
  podcastAudioInsertionPage: (podcastSlug) =>
    `/podcasts/${podcastSlug}/audio-insertion`,
  audioSeriesPage: (audioSeriesSlug) =>
    `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/audio-series/${audioSeriesSlug}`,
  audioSeriesEpisodesPage: (audioSeriesSlug) =>
    `/audio-series/${audioSeriesSlug}/episodes`,
  audioSeriesAudioInsertionPage: (audioSeriesSlug) =>
    `/audio-series/${audioSeriesSlug}/audio-insertion`,
  audioSeriesEpisodesMetricPage: (podcastSlug, episodeId) =>
    `/audio-series/${podcastSlug}/episodes/${episodeId}/metric`,
  podcastPromotionPage: (podcastSlug) => `/podcasts/${podcastSlug}/promotions`,
  audioSeriesPromotionPage: (audioSeriesSlug) =>
    `/audio-series/${audioSeriesSlug}/promotions`,
  podcastSubscribersPage: (podcastSlug) =>
    `/podcasts/${podcastSlug}/subscribers`,
  audioSeriesCustomersPage: (audioSeriesSlug) =>
    `/audio-series/${audioSeriesSlug}/customers`,
  donationSubscribersPage: (podcastSlug) =>
    `/donation/${podcastSlug}/subscribers`,
  podcastMetricsPage: (podcastSlug) => `/podcasts/${podcastSlug}/metric`,
  audioSeriesMetricsPage: (podcastSlug) => `/audio-series/${podcastSlug}/metric`,
  createPodcastEpisode: (podcastSlug) =>
    `/podcasts/${podcastSlug}/episodes/create`,
  createAudioSeriesEpisode: (audioSeriesSlug) =>
    `/audio-series/${audioSeriesSlug}/episodes/create`,
  editPodcastEpisode: (podcastSlug, episodeId) =>
    `/podcasts/${podcastSlug}/episodes/edit/${episodeId}`,
  editAudioSeriesEpisode: (audioSeriesSlug, episodeId) =>
    `/audio-series/${audioSeriesSlug}/episodes/edit/${episodeId}`,
  podcastEpisodePage: (podcastSlug, episodeId) =>
    `/podcasts/${podcastSlug}/episodes/${episodeId}`,
  podcastEpisodesMetricPage: (podcastSlug, episodeId) =>
    `/podcasts/${podcastSlug}/episodes/${episodeId}/metric`,
  audioSeriesEpisodePage: (audioSeriesSlug, audioId) =>
    `/audio-series/${audioSeriesSlug}/episodes/${audioId}`,
  podcastPublicPage: (creatorName, podcastSlug) =>
    `${
      process.env.NEXT_PUBLIC_IS_LOCAL === 'local'
        ? `http://staging-${creatorName}.localhost:3000/p/${podcastSlug}`
        : `https://${
            process.env.NEXT_PUBLIC_IS_LOCAL === 'dev' && 'staging-'
          }${creatorName}.mumbler.io/p/${podcastSlug}`
    }`,
  creatorPage: `/creator-page`,
  accountProfile: `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/profile`,
  newsletter: `/newsletter`,
  billing: `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/billing`,
  plans: '/plans',
  previewNewsletter: (newsletterId) => `/newsletter-preview/${newsletterId}`,
  home: (lang = 'en') => `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/`,
  thanks: '/thanks',
  features: (lang = 'en') =>
    `${
      lang === 'es'
        ? `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/funcionalidades/`
        : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/features/`
    }`,
  pricing: (lang = 'en') =>
    `${
      lang === 'es'
        ? `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/precios/`
        : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/pricing/`
    }`,
  creatorStories: (lang = 'en') =>
    `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/creator-stories/`,
  team: (lang) =>
    `${
      lang === 'es'
        ? `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/equipo/`
        : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/team/`
    }`,
  privacyPolicy: (lang) =>
    `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/privacy-policy/`,
  help: `https://help.mumbler.io/`,
  discover: (lang = 'en') =>
    `${
      lang === 'es'
        ? `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/descubrir/`
        : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/discover/`
    }`,
  blog: (lang = 'en') =>
    `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/blog/`,
  webinars: (lang) =>
    `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/webinars/`,
  resources: (lang) =>
    `${
      lang === 'en'
        ? `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/resources/`
        : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/recursos/`
    }`,
  podcastHosting: (lang) =>
    `${
      lang === 'es'
        ? `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/hosting-podcast/`
        : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/podcast-hosting/`
    }`,
  // discoverPage: (lang = 'en') => `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${lang}/discover`,
  goPaid: (lang) => `/${lang}/go-paid`,
  calculadora: (lang) => `/${lang}/calculadora`,
  manifesto: (lang) => `/${lang}/manifesto`,
  podcast: 'https://mumbler.io/podcast',
  // podcastPage: (podcastSlug) => `/${podcastSlug}`,
  editPodcastPage: (podcastSlug) => `/edit/podcasts/${podcastSlug}`,
  newsletterDetail: (newsletterId) =>
    `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/newsletter/${newsletterId}`,
  editAudioSeriesPage: (audioSeriesSlug) =>
    `/edit/audio-series/${audioSeriesSlug}`,
  podcastContact: (podcastSlug) => `/${podcastSlug}/contact`,
  podcastPodcatcher: (podcastSlug) => `/${podcastSlug}/podcatcher`,
  login: (lang) => `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/${lang}/login`,
  forgotPassword: (lang) => `/${lang}/login/forgot-password`,
  register: (lang) =>
    `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/${lang}/register`,
  registerPayment: (lang = '') =>
    lang
      ? `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/${lang}/register/payment`
      : `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/register/payment`,
  donationPayment: `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/register/donation-payment`,
  accountBillingMethods: (lang = '') =>
    lang
      ? `/${lang}/account/billing/payment-methods`
      : `/account/billing/payment-methods`,
  accountBillingReceipts: (lang = '') =>
    lang ? `/${lang}/account/billing/receipts` : `/account/billing/receipts`,
  accountPodcasts: (lang = '') =>
    lang ? `/${lang}/account/podcasts` : `/account/podcasts`,
  accountPodcastPage: (podcastSlug, lang = '') =>
    lang
      ? `/${lang}/account/podcasts/${podcastSlug}`
      : `/account/podcasts/${podcastSlug}`,
  accountAudioSeriesPage: (audioSeriesSlug, lang = '') =>
    lang
      ? `/${lang}/account/audio-series/${audioSeriesSlug}`
      : `/account/audio-series/${audioSeriesSlug}`,
  addCard: (lang) => (lang ? `/${lang}/add/card` : `/add/card`),
  promotionPage: (podcastSlug, lang = '') =>
    lang
      ? `/${lang}/account/podcasts/${podcastSlug}/promotions`
      : `/account/podcasts/${podcastSlug}/promotions`,
  audioPromotionPage: (podcastSlug, lang = '') =>
    lang
      ? `/${lang}/account/audio-series/${podcastSlug}/promotions`
      : `/account/audio-series/${podcastSlug}/promotions`,
  subscribersPage: (podcastSlug, lang = '') =>
    lang
      ? `/${lang}/account/podcasts/${podcastSlug}/subscribers`
      : `/account/podcasts/${podcastSlug}/subscribers`,
  customersPage: (audioSeriesSlug, lang = '') =>
    lang
      ? `/${lang}/account/audio-series/${audioSeriesSlug}/customers`
      : `/account/audio-series/${audioSeriesSlug}/customers`,
  episodesPage: (podcastSlug, lang = '') =>
    lang
      ? `/${lang}/account/podcasts/${podcastSlug}/episodes`
      : `/account/podcasts/${podcastSlug}/episodes`,
  audioEpisodesPage: (audioSeriesSlug, lang = '') =>
    lang
      ? `/${lang}/account/audio-series/${audioSeriesSlug}/episodes`
      : `/account/audio-series/${audioSeriesSlug}/episodes`,
  episodePage: (podcastSlug, episodeId, lang = '') =>
    lang
      ? `/${lang}/account/podcasts/${podcastSlug}/episodes/${episodeId}`
      : `/account/podcasts/${podcastSlug}/episodes/${episodeId}`,
  audioEpisodePage: (audioSeriesSlug, audioId, lang = '') =>
    lang
      ? `/${lang}/account/audio-series/${audioSeriesSlug}/episodes/${audioId}`
      : `/account/audio-series/${audioSeriesSlug}/episodes/${audioId}`,
  editEpisode: (podcastSlug, episodeId, lang = '') =>
    lang
      ? `/${lang}/account/podcasts/${podcastSlug}/episodes/edit/${episodeId}`
      : `/account/podcasts/${podcastSlug}/episodes/edit/${episodeId}`,
  // editAudioSeriesEpisode: (audioSeriesSlug, episodeId, lang = '') =>
  //   lang
  //     ? `/${lang}/account/audio-series/${audioSeriesSlug}/episodes/edit/${episodeId}`
  //     : `/account/audio-series/${audioSeriesSlug}/episodes/edit/${episodeId}`,
  createEpisode: (podcastSlug, lang = '') =>
    lang
      ? `/${lang}/account/podcasts/${podcastSlug}/episodes/create`
      : `/account/podcasts/${podcastSlug}/episodes/create`,
  error404: `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/404`,
  unAuthorization: `${process.env.NEXT_PUBLIC_APP_FRONTEND_URL}/error`,
  // createAudioSeriesEpisode: (audioSeriesSlug, lang = '') =>
  //   lang
  //     ? `/${lang}/account/audio-series/${audioSeriesSlug}/episodes/create`
  //     : `/account/audio-series/${audioSeriesSlug}/episodes/create`,
  // createPodcast: (lang = '') =>
  //   lang ? `/${lang}/account/podcasts/create` : `/account/podcasts/create`,
  // blog: (lang = 'en') =>
  //     lang === 'en'
  //         ? 'https://getpaid.mumbler.io/'
  //         : 'https://monetiza.mumbler.io/',
};

export default routes;
