import Router from 'next/router';
import ax from 'axios';
import Cookies from 'lib/js-cookie';

const cancelTokens = [];

ax.defaults.headers.post['Content-Type'] = 'application/json';
ax.defaults.validateStatus = (status) => status >= 200 && status < 500; // default
// ax.defaults.headers.common['Origin'] = window?.location?.origin;

const axios = ax.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

const { CancelToken } = ax;
const source = CancelToken.source();

axios.CancelToken = source;

axios.interceptors.response.use(undefined, (error) => {
  if (!error?.message?.isCancel) {
    switch (error?.response?.status) {
      default:
        if (typeof window !== 'undefined') {
          Router.push('/error');
        }
        break;
    }
  }
  return Promise.resolve(error);
});

const getAuthToken = (ssrToken = '') => {
  const token = ssrToken || Cookies.get('token');
  return { headers: { Authorization: `Token ${token}` } };
};

export { axios, getAuthToken, cancelTokens };
