import { formatNumber } from '../helpers';

const es = {};

es.account = {
  downloadCSV: `Descargar CSV`,
  moreSubscribers: `Más suscriptores`,
  moreCustomers: `Mas clientes`,
  subscribers: `Suscriptores`,
  metrics: 'Métricas',
  promotions: `Promociones`,
  coupons: `Cupones`,
  pricingPlans: `Planes de precios`,
  activateAnnualPlan: 'Activar plan anual',
  price: 'Precio',
  purchases: `Compras`,
  customers: `Clientes`,
  noCard: 'No has añadido ninguna tarjeta',
  addCreditCard: 'Añade tu tarjeta bancaria',
  deleteConfirmationMessage: `<p>Si eliminas esta tarjeta, todas tus suscripciones se cancelaran. En lugar de eliminarla, te sugerimos que la cambies por otra tarjeta. </br> Todas tus donaciones recurrentes se van a cancelar de inmediato. Para continuar donando, tendrás que crear la donación de nuevo después de añadir la nueva targeta.</p>`,
  removeCard: 'Eliminar tarjeta',
  cardDeletedSuccessfully: 'Tarjeta eliminada satisfactoriamente',
  receipts: 'Recibos',
  creditCard: 'Tarjeta bancaria',
  myReceipts: 'Mis recibos',
  copyright2020: 'Copyright 2020',
  youArePayingForYourSubscriptionsWithThisCreditCard:
    'Estás pagando tus suscripciones con esta tarjeta bancaria',
  delete: 'Eliminar',
  thisCard: 'esta tarjeta',
  changeCreditCard: 'Cambiar tarjeta bancaria',
  enterYourDetailsToConfirm: 'Indica tus datos para confirmar',
  dashboard: 'Panel de control',
  episodes: 'Episodios',
  audioInsertion: 'Audio inserciones',
  audios: 'Audios',
  edit: 'Edita',
  hostedBy: 'Presentado por',
  viewPublicSite: 'Ver la Página Pública',
  plays: 'Reproducciones',
  totalPlays: 'Reproducciones totales',
  type: 'Type',
  earns: 'Ingresos',
  suscribers: 'Suscriptores',
  MRRMonthlyRecurringRevenue: 'MRR: Ingresos mensuales recurrentes',
  sales: 'Ventas',
  claimYourAmount: 'Solicita el pago',
  lastPayment: 'En camino:',
  youCannotClaimPayoutUntil50: 'No puedes solicitar el pago hasta los 50',
  youCanClaimOnlyIfTheAmountIsMoreThan50:
    'Sólo puedes solicitar el pago si la cantidad es superior a 50',
  requestForPayout: 'Solicitar pago',
  myPodcasts: 'Mis podcasts',
  myAudios: 'Mis audios',
  discoverBestAudioContent: 'Descubre el mejor contenido en audio',
  allContent: 'Todo el contenido',
  allCategories: 'Todas las categorias',
  mySubscriptions: 'Mis suscripciones',
  myPurchases: 'Mis compras',
  youHaveNotSubscribedToAnyPodcast: 'No te has suscrito a ningún podcast',
  createPodcast: 'Crea un podcast',
  create: 'Crear',
  totalNumberOfPlays: 'Número total de reproducciones:',
  newEpisode: 'Nuevo episodio',
  newAudio: 'Nuevo Audio',
  addAudio: 'Añadir Audio',
  editEpisode: 'Editar el episodio',
  editAudio: 'Editar audio',
  moreEpisodes: 'Más episodios',
  moreAudios: 'Más audios',
  emailConfirmation:
    'Encontrarás un email de confirmación en tu bandeja de entrada. Haz clic en el enlace para completar el registro.',
  alreadyAccount: 'Ya tienes una cuenta?',
  profileUpdateHeading: 'Perfil actualizado',
  profileUpdateDescription: 'Tu perfil se ha actualizado correctamente',
  deleteAccountPrompt: 'Seguro que quieres eliminar tu cuenta?',
  deleteAccountHeading: 'Hecho',
  deleteAccountDescription: 'Tu cuenta será cancelada en las próximas 24h',
  deleteAccountButton: 'Eliminar cuenta',
  updateProfileButton: 'Actualizar mi perfil',
  yourNameLabel: 'Tu nombre',
  linksRestricted:
    'Lo siento, los enlaces no están permitidos en la descripción',
  descriptionLabel: 'Descripción',
  country: 'País',
  countryError: 'Por favor selecciona tu país',
  bankAccountHeading: 'Tu cuenta bancaria',
  // bankAccountDescription: 'Para poderte pagar lo que ganes con tus podcasts',
  // ibanNumberLabel: 'Tu número IBAN',
  whereDoWePayYou: 'Dónde te pagamos',
  bankAccountDescription:
    'Si tienes una cuenta bancaria europea, nos puedes dejar el número IBAN de tu cuenta para que te paguemos ahí.',
  ibanNumberLabel: 'El IBAN de tu cuenta',
  ibanError: 'Este IBAN no es válido',
  payPalAccountDescription:
    'También te podemos pagar via PayPal. Déjanos el email que tengas asociado a tu cuenta de PayPal y te enviaremos el dinero ahí.',
  payPalNumberLabel: 'Email de tu cuenta de PayPal',
  payPalError: 'Email inválido',
  vatNumberHeading: 'Detalles fiscales',
  vatNumberLabel: 'Número fiscal',
  billingNameLabel: 'Nombre para facturas',
  billingAddressLabel: 'Dirección',
  vatError: 'Número fiscal inválido',
  passwordHeading: 'Cambia tu contraseña',
  choosePasswordLabel: 'Por favor elige una contraseña',
  passwordError: 'La contraseña debe tener como mínimo 8 caracteres',
  passwordChangeTitle: 'La contraseña se ha modificado correctamente',
  passwordChangeDescription: 'Tu nueva contraseña ha sustituido la anterior',
  confirmPasswordError: 'La contraseña no coincide',
  oldPasswordLabel: 'Indica la antigua contraseña',
  newPasswordLabel: 'Nueva contraseña',
  repeatPasswordLabel: 'Repite la contraseña',
  forgotPasswordConfirm:
    'En tu bandeja de entrada encontrarás un enlace para crear una nueva contraseña',
  passwordResetEmailHasBeenSent:
    'Se ha enviado un correo electrónico para crear una nueva contraseña',
  resetPasswordButton: 'Cambiar contraseña',
  termsAgree: 'Estoy de acuerdo con',
  termsError: 'Por favor acepta nuestros Términos y Condiciones',
  termsAnchorText: 'términos y condiciones',
  newsLetter:
    'Quiero recibir actualizaciones de producto y la newsletter de Mumbler',
  startMyPodcast: 'Empezar mi podcast',
  startMyAudio: 'Empezar',
  registerTitle: 'Registrarme y continuar con el pago',
  login: 'Entrar',
  facebookLogin: 'Iniciar sesión Facebook  ',
  addDomainToViewPublicSite:
    'No has añadido un dominio a este usuario. Por favor, añade un dominio en la configuración del creador para ver tu sitio público.',
  addDomainToCreatePodcast:
    'No has añadido un dominio a este usuario. Por favor, añade un dominio en la configuración del creador para ver tu sitio público.',
  goToCreatorSettings: 'Ir a configuración del creador',
  donors: 'Donantes',
};
es.common = {
  schedule: 'Programar',
  publish: 'Publicar',
  ok: 'Aceptar',
  renewBtn: 'Renovar suscripción',
  or: 'o',
  shareText: ({ title }) => `Escucha mi #podcast ${title}`,
  sharePodcast: `Comparte este podcast`,
  shareAudio: `Comparte esta audio serie`,
  addCard: 'Añadir tarjeta',
  cardAdded: 'Tarjeta agregada',
  cardAddedSuccessfully: 'Tarjeta agregada con éxito',
  perMonth: 'por mes',
  month: 'mes',
  done: '¡Hecho!',
  areYouSure: 'Seguro que lo quieres hacer?',
  cancel: 'Cancelar',
  close: 'Cerrar',
  share: 'COMPARTE',
  sure: 'Si Seguro',
  save: 'Guardar',
  getStarted: 'Empezar Gratis',
  welcome: 'Hola',
  goinside: 'Entrar',
  calculadora: 'CALCULADORA',
  pricing: 'MONETIZA',
  'pricing&Features': 'Precios & Funcionalidades',
  mumblerIsTheEasiestWayToCreateASubscriptionPodcast:
    'Mumbler es la forma más fácil de crear un podcast de suscripción',
  ofTheMonthlyIncomeYouEarnOnMumbler:
    'de los ingresos mensuales que tengas en Mumbler',
  allIncluded: 'TODO INCLUIDO',
  podcastHosting: 'Hosting para tu podcast',
  podcastLandingPage: 'Página para tu podcast',
  highQualityAudio: 'Audio de alta calidad',
  insights: 'Estadísticas',
  prioritySupport: 'Soporte prioritario',
  paymentProcessing: 'Procesamiento de pagos',
  faqs: 'FAQs',
  works: 'FUNCIONA',
  'howItWorks?': 'Cómo funciona?',
  beIndependent: 'Sé independiente',
  joinMumblerInLessTimeThanItTakesToSipCoffe:
    'Únete a Mumbler en un abrir y cerrar de ojos',
  uploadYourFirstEpisode: 'Sube tu primer episodio',
  uploadYourFirstEpisodeToMumbler: 'Sube el primer episodio de tu podcast',
  enjoyYourlanding: 'Disfruta la página de tu podcast',
  'we’llBuildAnAmazingLandingPageForYourPodcast':
    'Construiremos una página espectacular para tu podcast',
  growYourBusiness: 'Haz crecer tu negocio',
  startSellingYourPremiumPodcastToYourCommunity:
    'Empieza a vender tu podcast de suscripción a tu comunidad',
  error: 'Error',
  error404PageNotFound: 'Error 404… Esta página no existe',
  pleaseTryAgainAfterSomeTimeIfYouLandedHereByMistake:
    'Por favor inténtalo de nuevo. Si estás aquí por error,',
  letusKnow: 'nos lo puedes decir',
  'An error occurred on server':
    'Un error {{statusCode}} ha ocurrido en el servidor',
  'An error occurred on client':
    'Un error {{statusCode}} ha ocurrido en el cliente',
  clickToUpload: 'Haz click para subir o arrastra el archivo aquí',
  audioFileRequired: 'El tipo de archivo debe ser audio / *',
  fileSizeTooLarge: 'Tamaño de archivo demasiado grande',
  onlyMp3: 'Sólo puedes subir archivos en formato mp3',
  dropFile: 'Arrastra tu archivo aquí',
  dropPicture: 'Arrastra tu foto de perfil aquí',
  myMumbler: 'Mi Mumbler',
  dashboard: 'Panel de control',
  myProfile: 'Mi Perfil',
  billing: 'Facturación',
  logout: 'Salir',
  scroll: 'MIRA',
  home: 'MUMBLER',
  manifesto: 'MANIFIESTO',
  team: 'EQUIPO',
  yourName: 'Tu nombre',
  yourNameError: 'Por favor indica tu nombre',
  unsubscribe: 'Cancelar suscripción',
  subscribe: 'Suscríbete',
  emailAddress: 'Correo electrónico',
  emailRequired: 'El correo electrónico es obligatorio',
  emailLabel: 'Por favor indica tu correo electrónico',
  passwordLabel: 'Contraseña',
  forgotPassword: 'Has olvidado tu contraseña?',
  passwordError: 'Por favor indica tu contraseña',
  invalidEmailError:
    'Por favor indica una dirección de correo electrónico válida',
  subscriptionEnd: ({ date }) => `La suscripción finaliza el ${date}`,
  unsubscribeQuestion: ({ podcast, date }) =>
    `Estás a punto de cancelar tu suscripción a ${podcast}. Si lo haces, a partir del ${date} perderás acceso a los episodios ya publicados y a los nuevos episodios que publique ${podcast}.`,
  renewText: ({ date }) =>
    `Has cancelado la suscripción. Lo que significa que no podrás seguir escuchando este podcast a partir del ${date}. Si no quieres perder el acceso a los episodios, renueva la suscripción.`,
  unsubscribedTitle: 'Te has desuscrito',
  unsubscribedmessage: 'Te has desuscrito correctamente de este podcast',
  messageSentTitle: 'Mensaje enviado',
  messageSentDescription:
    'Hemos recibido tu mensaje y te responderemos muy pronto.',
  messageSent: 'Tu mensaje ha sido enviado.',
  thankYou: 'Gracias!',
  verificationLinkMessage:
    'Te hemos enviado un enlace para verificar tu correo electrónico.',
  accountCreationMessage:
    'Su cuenta ha sido creada, creemos su primer podcast.',
  accountCreationMessageAudio: 'Tu cuenta ha sido creada',
  loginButton: 'Entrar',
  settings: 'Configuración',
  creatorPage: 'Página de creador',
  privacyPolicy: 'Política de privacidad',
  termsAndConditions: 'Términos y condiciones',
  search: 'Búsqueda',
  nameIsRequired: 'El nombre es un campo requerido',
  emailIsRequired: 'El email es un campo requerido',
  episodesIsRequired: 'El episodios es un campo requerido',
  audioInsertionName: 'Nombre de la audio inserción',
  next: 'Siguiente',
  width: 'Ancho',
  height: 'Alto',
  add: 'Añadir',
  embedURL: 'Embeber URL',
  videoURL: 'URL del vídeo',
  previous: 'Anterior',
  pleaseCropImage: 'Por favor, recorta la imagen',
  renewSubscription: 'Renovar suscripción',
  changesSavedSuccessfully: 'Cambios guardados exitosamente',
  scheduled: 'Programado',
  invalidSlugName: 'Nombre de slug inválido',
  warning: 'Atención',
  products: 'Productos',
  audience: 'Audiencia',
  newsletter: 'Newsletter',
  myFeed: 'Mi Feed',
  discover: 'Descubrir',
  emailUserInvalid:
    'Lo sentimos, solo se permiten letras (a-z), números (0-9) y puntos (.).',
  thanks: 'Gracias!',
  nowYouCanEnjoyAllPROMumblerFeatures:
    'Ya puedes disfrutar de todas las funcionalidades PRO de Mumbler.',
  congratulations: 'Enhorabuena!',
  startThankYouBtn: 'Continuar',
  logoutForSecurityPurpose: 'Deslogueado por razones de seguridad',
  youRegisteredUsingGoogleOrFacebookButton:
    'Te diste de alta con el botón de Google o Facebook.',
  createAudioInsertion: 'Crea audio inserción',
  audioInsertionDateConflicts:
    'Este podcast ya tiene una campaña para las mismas fechas.',
};
es.pricing = {
  getPaid: 'Vive de tu podcast',
  getPaidInfo: `Publicar tu podcast es gratis, para siempre. Solo ganamos dinero cuando tú ganas dinero. Nuestra comisión es el 10% + 30 céntimos por cada transacción.`,
  earnings: '¿Cuánto podrías ganar con tu podcast?',
  earn: 'gana',
};
es.footer = {
  copyright: ({ year }) => `Copyright ${year}`,
  home: 'Mumbler',
  manifesto: 'Manifiesto',
  team: 'Equipo',
  discover: 'Discover',
  podcast: 'Podcast',
  legal: 'Legal',
  help: 'Ayuda',
  blog: 'Blog',
  features: 'Funcionalidades',
  pricing: 'Precios',
  creatorStories: 'Historias de creadores',
  webinars: 'Webinars',
  resources: 'Recursos',
  podcastHosting: 'Hosting para podcasts',
};
es.helpError = {
  help: 'AYUDA',
  helpDescription:
    'Estamos aquí para ayudar. Dinos que ocurre y responderemos cuanto antes',
  Help: 'Ayuda',
  yourEmailAddress: 'Tu dirección de correo electrónico *',
  typeHere: 'Escribe aquí *',
  send: 'Enviar',
  error: 'Error',
  somethingWentWrong: 'Algo salió mal',
  youAreNotAuthorizedToVisitThisPageFromYourMumblerAccount:
    'No tienes autorización para acceder a esta cuenta de Mumbler.',
  ifYouLandedHereByMistake: 'Si estás aquí por error,',
  goToHome: 'Ir a Mumbler',
};
es.home = {
  titleTag: 'Mumbler – Vender tus audios y podcast nunca fue tan fácil',
  create: 'CREA',
  createYourSubscriptionPodcast: ({ changingText }) =>
    `Monetiza tu audiencia con ${changingText}`,
  createYourPodcastBusinessTheEasyWay:
    'En menos de 1 minuto y a golpe de click.',
  beFreeBeAProfessionalPodcaster: 'Solo ganamos dinero cuando tú lo ganas.',
  beJustYou: 'Sé simplemente tú.',
  audioPodcasts: 'Audio podcasts',
  playPodcast: 'Reproduce el podcast',
  on: 'EN',
  mumbler: 'MUMBLER',
  youCanReceiveMonthlyPayments: 'Monetiza tu audiencia',
  createASubscriptionPodcast: 'Crea un podcast de suscripción',
  inMinutes: 'en pocos minutos',
  'giveAccesToYourEpisodesAndRecieveMonthlyPaymentFromYourAudienceThisIsNotAboutDonationsIt’sAboutToBuildABusiness':
    'Da acceso a tus episodios y recibe pagos mensuales de tu audiencia. Esto no va de donaciones, va de crear un negocio.',
  'whyAPodcastMembership?': 'Empieza a vender tus audios',
  whyToCreateABusinessWithYourPodcast:
    'Hacemos fácil la venta de tus podcasts y cualquier contenido en formato audio',
  be: 'Fácil',
  independent: 'y rápido',
  runYourOwnBusinessNotReliantOnAdsOrPaidSponsorshipBeFreeBeIndependent:
    'Construye tu negocio sin anuncios ni patrocinadores. Sé libre. Sé independiente.',
  get: 'Que',
  paidToDoWhatYouLove: 'te paguen por hacer lo que te gusta',
  beTheProfesionalPodcasterYouAlwaysWantToBeYourPodcastIsYourBusiness:
    'Vive de tus contenidos como siempre quisiste. Tu podcast o tus audios son tu negocio.',
  build: 'Construye',
  yourAudience: 'tu audiencia',
  getAccessToYourFansDirectlyAndBuildAStrongerCommunity:
    'Consigue una acceso directo con tu audiencia y construye una comunidad fuerte.',
  featured: ' Destacados',
  notablePodcastersAndAudioOnMumbler:
    'Estos son algunos de los podcasts y audios más molones en Mumbler  ',
  loadMore: 'Ver más',
  mumblerIcon: 'Icono de Mumbler',
  greatPodcastsAreValuable: 'Empieza a ganar dinero ahora',
  listenersPayDirectlyForPodcastsTheyCareAboutStartAcceptingPaidSubscriptionsInJustAFewMinutes:
    'Empieza a cobrar por tu podcast en pocos minutos y a golpe de click.',
};
es.calculadora = {
  calculadora: 'Calculadora',
  ourCalculator: 'Nuestra Calculadora',
};
es.manifest = {
  manifesto: 'MANIFIESTO',
  ourManifesto: '¿Por qué elegir Mumbler?',
  whyToChooseMumbler: 'Nuestro manifiesto',
  value: 'Todo tiene un precio',
  podcastsLikeAllContentHaveALotOfValueEvenIfYouAreNotPayingForIt:
    'Aunque no lo estés pagando, los podcasts tienen mucho valor.',
  option: 'Vivir del podcasting',
  podcastersLikeAllContentCreatorsMustHaveTheOptionToLiveFromPodcasting:
    'Los podcasters debemos tener la opción de vivir del podcasting.',
  noAdditionalGifts: 'Sin regalos adicionales',
  aPodcastIsRewardEnoughForYourListenersNoAdditionalGiftsAreNecessaryToJustifyTheirSupport:
    'Tu podcast es suficiente recompensa para tus oyentes. No hacen falta regalos adicionales para justificar su suscripción.',
  mumblersMission: 'Nuestra misión',
  atMumblerWeHaveAMissionHelpPodcastersLiveFromTheirContent:
    'Tenemos una misión: ayudarte a vivir de tu podcast.',
  weWantToLiveFromPodcasting: 'Queremos vivir de nuestros podcasts',
  weArePodcastersAndWeWantToLiveFromPodcastingForThisReasonWeAreTheFirstToUseMumbler:
    'Somos podcasters y queremos vivir de nuestros podcasts. Por este motivo somos los primeros en usar Mumbler.',
  builtByListening: `Te escuchamos`,
  mumblerIsBuiltByListeningToOurCustomersIfAPodcasterProposesAnIdeaAndWeSeeThatItCanHelpManyOtherPodcastersWeIncludeItInOurRoadmap:
    'Mumbler se construye escuchándote. Creamos nuestro roadmap en función de tus necesidades.',
  addingValueToPodcasters: 'Ayudarte más y mejor',
  mumblerOnlyMakesSenseIfWeAddValueToPodcastersAllOurDecisionsAreAimedAtHelpingOurClientsMoreAndBetter:
    'Todas nuestras decisiones están orientadas a ayudarte más y mejor.',
  yourOwnershipStaysYours: 'Lo tuyo sigue siendo tuyo',
  theContentAndItsSubscribersAreAlwaysFromThePodcasterWeOnlyHelpToMonetizeItIfYouWantToStopUsingMumblerWeWillGiveYouTheToolsToTakeYourContentContactsAndWeWishYouGoodLuckWhereverYouGo:
    'El contenido y los suscriptores son tuyos, nosotros sólo te ayudamos a monetizar tu podcast. Si quieres dejar de usar Mumbler, te daremos las herramientas para que te lleves tu contenido/suscriptores y te desearemos suerte, vayas donde vayas.',
  transparent: 'Somos transparentes',
  weAreTransparentWeTellYouThingsAsTheyAre:
    'Te contamos las cosas tal y como son.',
  solutionFocussed: 'Somos humanos',
  weArePeopleAndWeMakeMistakesWhenThisHappensWeAcceptItAskForForgivenessAndFindSolutions:
    'Cometemos errores. Cuando esto sucede, lo asumimos, pedimos perdón y buscamos soluciones.',
};
es.podcast = {
  pubEpisodeErrorTitle: 'Has alcanzado el máximo de episodios gratis',
  pubEpisodeErrorInfo:
    'No puedes publicar más episodios públicos que privados. Si quieres publicar un nuevo episodio público, tienes que publicar un nuevo episodio privado o convertir uno de los episodios públicos que ya tienes publicados a privado.',
  copyPrivateRSSLink: 'Copiar Link RSS privado',
  podcatcherWarning: `¡Cuidado! No compartas la URL de tu feed privado de forma pública ya que contiene una serie de parámetros que pueden permitir un acceso no autorizado a tu cuenta.`,
  renewTitle: 'La suscripción se ha renovado',
  renewMsg: 'Has renovado la suscripción al podcast con éxito.',
  txtColor: 'Color del texto',
  btnColor: 'Color del botón',
  username: 'usuario',
  website: 'sitioweb.com',
  priceRange: ({ from, to }) => `Precio: ${from} a ${to}`,
  priceRangeAudioSeries: ({ from }) => `Precio: mayor o igual a ${from}`,
  maxPriceLimit: (maxPrice) => `Price up to ${maxPrice}`,
  play: 'Escuchar',
  hostedBy: 'Presentado por',
  contactPodcaster: 'Contactar',
  trailer: 'Trailer',
  readMore: 'Leer más',
  readLess: 'Read less',
  latestBlogPosts: 'Últimas publicaciones del blog',
  episodes: 'Episodios',
  noEpisodesFound: 'No se ha encontrado ningún episodio',
  noAudiosFound: 'No se encontraron audios',
  free: 'Gratis',
  onlyAvailableForSubscribersSubscribe:
    'Estás a solo un click de disfrutarlo. ¡Suscríbete',
  onlyAvailableForPurchaserCustomers:
    'Estás a solo un click de disfrutarlo. ¡Compralo',
  now: 'ahora',
  moreEpisodes: 'Más episodios',
  moreAudios: 'Más audios',
  send: 'ENVIAR',
  typeYourMessageHere: 'Escribe tu mensaje aquí *',
  howToAddToYourPodcatcher: 'Cómo añadir el podcast a tu podcatcher',
  addToYourPodcatcher: 'Añádelo a tu app de podcasts',
  applePodcasts: 'Apple Podcasts',
  overcast: 'Overcast',
  pocketCasts: 'Pocket Casts',
  googlePodcasts: 'Google Podcasts',
  podcastGuru: 'PodcastGuru',
  podcastAddict: 'podcastAddict',
  spotify: 'Spotify',
  ivoox: 'Ivoox',
  youtube: 'Youtube',
  editPodcast: 'Editar podcast',
  editAudioSeries: 'Edit audio series',
  createAPodcast: 'Crear un podcast',
  by: 'por',
  goToPodcast: 'Ir al Podcast',
  goToAudio: 'Ir al audio',
  cardOwner: 'Propietario de la tarjeta',
  goBack: 'Volver',
  editEpisode: 'Editar el episodio',
  editAudio: 'Editar audio',
  podcastUpdateTitle: 'Podcast actualizado',
  audioSeriesUpdateTitle: 'Audio serie actualizada',
  podcastUpdateDescription: 'El Podcast se ha actualizado correctamente',
  audioSeriesUpdateDescription: 'Audio serie se ha actualizado con éxito',
  podcastDeletePrompt: 'Seguro que quieres eliminar este podcast?',
  podcastDeleteTitle: 'Eliminar el podcast',
  audioSeriesDeleteTitle: 'Eliminar audio serie',
  podcastDeleteDescription:
    'Hemos recibido tu petición para eliminar el podcast, haremos algunas comprobaciones y te contactaremos en seguida',
  audioSeriesDeleteDescription:
    'Hemos recibido tu solicitud de eliminación de audio serie, pronto nos pondremos en contacto contigo',
  podcastCreationTitle: 'Podcast creado',
  audioSeriesCreationTitle: 'Audio serie creada',
  podcastCreationDescription:
    'Tu podcast se ha creado, vamos a subir el primer episodio!.',
  audioSeriesCreationDescription:
    'Su audio serie ha sido creada, subamos el primer audio.',
  episodeUpdateSuccess:
    'Los detalles del episodios se han actualizado correctamente',
  audioUpdateSuccess: 'Detalles de audio actualizados con éxito',
  publicEpisodeError:
    'No es posible publicar más episodios gratuitos que de pago',
  publicEpisodeInfo:
    'Recuerda que existe un límite en el número de episodios públicos que puedes subir a Mumbler.',
  moreInfo: 'Más info aquí',
  episodePublishedTitle: 'Episodio publicado',
  audioPublishedTitle: 'Audio publicado',
  episodePublishedDescription:
    'Tu episodio ha sido publicado/programado. A tu audiencia le encantará!',
  audioPublishedDescription:
    'Su audio ha sido publicado/programado. A tu audiencia le encantará!',
  publishPastDateError: 'La fecha de publicación es en el pasado',
  dateTimeError: 'Por favor elije una hora y un día correctos',
  fileSizeError: 'El tamaño del archivo debe ser menor de 300Mb',
  episodeNameLabel: 'Nombre del episodio',
  audioNameLabel: 'Nombre de audio',
  episodeNameError: 'Por favor ingrese el nombre',
  episodeNameRequiredError:
    'Se requiere el nombre del episodio antes de cargar el archivo del episodio',
  whatKindOfContent: '¿Qué tipo de contenido quieres crear?',
  recurringMonthlyPayment: 'Pago mensual recurrente',
  oneSinglePayment: 'Un pago único',
  podcastNameLabel: 'Nombre del podcast',
  audioSeriesNameLabel: 'Nombre de la audio serie',
  podcastNameError: 'Por favor ingrese el nombre',
  maxCharLimit: ({ length }) => `Máximo ${length} caracteres`,
  maxLimitError: 'Has superado la longitud máxima',
  episodeFileError: 'Por favor cargue el archivo',
  episodePromoFileError: 'Sube el archivo del episodio promocional',
  episodeDescriptionLabel: 'Descripción del episodio',
  audioDescriptionLabel: 'Audio descripción',
  episodeDescriptionError: 'Por favor ingrese la descripción',
  episodePrivateNoteLabel: 'Notas privadas',
  episodePrivateNoteError: 'Por favor ingrese nota privada',
  podcastDescriptionLabel: 'Descripción del podcast',
  audioSeriesDescriptionLabel: 'Descripción de la audio serie',
  podcastDescriptionError: 'Por favor ingrese la descripción',
  podcastCoverLabel: 'Sube la portada de tu podcast',
  audioSeriesCoverLabel: 'Subir carátula de la audio serie',
  episodeCoverLabel: 'Sube la portada de tu episodio',
  audioCoverLabel: 'Subir carátula de audio',
  podcastCoverError: 'Por favor cargue la portada',
  episodeCoverError: 'Por favor sube la portada de tu episodio',
  applePodcastSizeRecommendation:
    'Te recomendamos que la portada sea de 3000x3000 píxeles para respetar las guías de estilo de Apple Podcasts.',
  releaseDateLabel: 'Fecha de publicación',
  episodeTypeLabel: 'Tipo de episodio',
  audioTypeLabel: 'Tipo de audio',
  episodeTypeError: 'Seleccione el tipo',
  episodeFileTitle: 'Subir episodio',
  audioFileTitle: 'Subir audio',
  uploadingMessage: 'Subiendo el audio',
  promoTitle: '(Opcional) Promo para el feed público',
  audioSeriesPromoTitle: '(Optional) Promoción para visitantes que no pagan',
  promoKnowledgeLink: 'https://help.mumbler.io/docs/es/18136050-promo',
  podcastCategoryLanguageHeading: 'Categoría del podcasts e idioma',
  audioSeriesCategoryLanguageHeading: 'Categoría e idioma de la audio serie',
  podcastCategoryError: 'Por favor selecciona un idioma',
  podcastLanguageError: 'Por favor selecciona un idioma',
  podcastCategoryPlaceholder: 'Categoría',
  podcastContentPlaceholder: 'Contenido',
  podcastLanguagePlaceholder: 'Idioma',
  noOptionFound: 'No se ha encontrado esta opción',
  sentEpisodeEmailLabel: 'No quiero enviar el email de nuevo episodio.',
  sentAudioEmailLabel: 'No quiero enviar el nuevo correo electrónico de audio.',
  emailMessageHeading:
    'Escribe el mensaje de correo electrónico para tus suscriptores',
  emailMessageHeadingAudio:
    'Escribe el mensaje de correo electrónico para sus compradores',
  emailMessageLabel: 'Mensaje de correo electrónico para tus suscriptores',
  emailMessageError: 'Por favor indica el mensaje de correo electrónico',
  welcomeMessageHeading:
    'Escribe un mensaje de bienvenida para tus nuevos suscriptores',
  welcomeMessageHeadingAudioSeries:
    'Escribe un mensaje de bienvenida a tu nuevo comprador',
  welcomeMessageLabel: 'Mensaje de bienvenida',
  welcomeMessageError: 'Por favor indica un mensaje de bienvenida',
  monthlyPriceHeading: 'Precio mensual',
  priceHeading: 'Precio',
  monthlyPriceLabel: 'Precio de la suscripción',
  monthlyPriceError: 'Por favor indica un precio',
  priceMaxError: ({ price, currency }) =>
    `El precio no debe ser más de ${currency}${price}`,
  priceMinError: ({ price, currency }) =>
    `El precio no debe ser inferior a ${currency}${price}`,
  customURLHeading: 'Tu URL personalizada',
  slugError: 'Por favor indica la URL personalizada',
  slugErrorMessage: 'La URL debe contener caracteres alfanuméricos',
  slugLabel: 'El nombre de tu podcast',
  slugLabelAudioSeries: 'El nombre de tu audio serie',
  slugEpisodeLabel: 'El nombre de tu episodio',
  slugAudioLabel: 'Tu nombre de audio',
  publicRssHeading: 'RSS público',
  publicRssDescription:
    'Este feed sólo incluye el trailer y los episodios gratis',
  privateRssHeading: "Podcast's Private RSS Feed",
  privateRssDescription: 'Este feed incluye los episodios privados.',
  socialLinksHeading: 'Dónde pueden encontrarte',
  listenNowOn: 'Escúchalo ahora en',
  episodeDeletePrompt: 'Seguro que quieres eliminar este episodio?',
  episodeDeleteTitle: 'Elimina el episodio',
  audioDeleteTitle: 'Eliminar audio',
  episodeDeleteSuccessfully: 'Episodio eliminado con éxito.',
  subcribe: 'Suscríbete',
  purchaseAudio: 'Comprar',
  onlyForSubscribersSubscribeNow:
    'Sólo disponible para suscriptores. Suscríbete ahora!',
  onlyForCustomerPurchaseNow:
    'Compra este audio. Disfruta este maravilloso contenido en formato audio.',
  cardExpiredMessage:
    'Tu tarjeta bancaria ha caducado o tiene algún problema. Por favor actualize la tarjeta o añade una nueva antes de suscribirte.',
  cardExpiredMessageAudioSeries:
    'Su tarjeta de crédito ha caducado o tiene algunos problemas. Actualice su tarjeta bancaria antes de comprar un nuevo audio.',
  subscribeTitle: `Nos encanta ver que quieres suscribirte`,
  purchaseTitle: `Nos alegramos de que quieras comprar`,
  subscribeDescription: `Por favor confirma tu suscripción.`,
  purchaseDescription: `Por favor confirma la compra.`,
  podcastWelcomeDialogTitle: 'Listos! Ahora démosle caña al boca/oreja.',
  podcastWelcomeDialogMessage: ({ name }) =>
    `Si quieres ayudar aún más a ${name}, comparte por qué has comprado su contenido.`,
  podcastShareMessage: ({ link }) =>
    `Acabo de comprar este audio tan genial ${link}`,
  keepSubscription: 'Mantener suscripción',
  subscribeButton: 'Suscribirse',
  purchaseButton: 'Compra',
  unsubscribeButton: 'Cancelar suscripción',
  noSubscriptionsNow: 'Este podcast no admite suscripciones temporalmente.',
  landingPageOptions: 'Opciones de la página',
  hideSubscribeButtons: 'No muestres los botones de suscripción.',
  hidePurchaseButtons: 'Ocultar botones de compra.',
  subscriptionButtonIsNotVisibleUntilYouUploadYourFirstAudio:
    'El botón de suscripción no se ve hasta que no subas el primer audio',
  updateCreditCardButton: 'Actualizar mi tarjeta',
  subscribe: ({ podcastPrice, currency, planType }) =>
    `Suscríbete ${formatNumber(podcastPrice)}${currency}/${
      planType === 'yearly' ? 'año' : 'mes'
    } + IVA`,
  renewSubscribe: ({ podcastPrice, currency, planType }) =>
    `Renovar suscripción ${formatNumber(podcastPrice)}${currency}/${
      planType === 'yearly' ? 'año' : 'mes'
    } + IVA`,
  purchase: ({ podcastPrice, currency }) =>
    `Compra ${formatNumber(podcastPrice)}${currency} + IVA`,
  purchaseBtnWithoutPrice: 'Comprar',
  subscribeBtnWithoutPrice: 'Suscríbete',
  subscribeBtnAnnualMessage: ({ podcastData, currency }) => {
    const percentSavings = Math.floor(
      ((podcastData.price * 12 - podcastData.annual_price) * 100) /
      (podcastData.price * 12),
    );
    let message = `O ${formatNumber(podcastData.annual_price)}${currency}/año`;
    if (percentSavings > 0) {
      message += ` (ahorra ${percentSavings}%)`;
    }
    return message;
  },
  upgradeToYearlyText: ({ price, currency }) =>
    `Pagar ${formatNumber(price)}${currency}/año`,
  upgradeToYearlySavingMessage: ({ podcastData, currency }) => {
    const diff = podcastData.price * 12 - podcastData.annual_price;
    const percentage = Math.floor((diff / (podcastData.price * 12)) * 100);
    const message = `Ahorra ${formatNumber(
      diff,
    )}${currency}/año (${percentage}%)`;
    if (diff > 0) return message;
    return '';
  },
  planPopupSubscribeMonthlyMessage: ({ podcastData, currency }) =>
    `Suscríbete ${formatNumber(podcastData?.price)}${currency}/mes + IVA`,
  planPopupSubscribeYearlyMessage: ({ podcastData, currency }) =>
    `Suscríbete ${formatNumber(
      podcastData?.annual_price,
    )}${currency}/año + IVA`,
  planPopupSubscribeYearlySavings: ({ podcastData }) => {
    const diff = podcastData?.price * 12 - podcastData?.annual_price;
    const percentage = Math.floor((diff / (podcastData?.price * 12)) * 100);
    if (percentage > 0) return `Ahorra ${percentage}% pagando anualmente`;
    return '';
  },
  createCouponButton: 'Crear nuevo cupón',
  createCouponModalTitle: 'Creando nuevo cupón',
  createCouponModalCoupon: 'Cupón',
  createCouponModalProduct: 'Producto',
  createCouponModalDiscount: 'Descuento',
  createCouponModalDiscountType: 'Tipo de descuento',
  createCouponModalRedemptions: 'Usos',
  createCouponModalDuration: 'Duración',
  createCouponModalMonths: 'Meses',
  createCouponModalSubmit: 'Guardar',
  percentage: 'Porcentaje',
  flat: 'Fijo',
  once: 'Único',
  repeating: 'Repetido',
  lifetime: 'Para siempre',
  select: 'Seleccionar',
  chooseYourPlan: 'Elige tu plan',
  dontSendNewEpEmail: 'No enviar nuevos episodios por email',
  stopChargingUser: 'Parar de cobrar a los usuarios',
  allowUserToAccessContent: 'Permitir a los usuarios acceder al contenido',
  freePodcastConfirmationMessage: `Cambiarlo a podcast gratuito hará que pierdas todos tus suscriptores actuales.
Si quieres mantenerlos, por favor, añade un precio al podcast.`,
  products: 'Productos',
  pleaseCompleteThePodcastInformation:
    'Por favor completa la información del podcast.',
  becomePRO: 'Pásate a PRO',
  toPublishFreeProducts: 'para publicar contenidos gratuitos',
  otherEpisodes: 'Otros episodios',
  otherCategoryEpisodesYouMayAlsoLike: ({ category }) =>
    `Otros episodios de ${category} que te pueden gustar`,
  lastUpdatedEpisodesOnMumbler: 'Últimos episodios publicados en Mumbler',
  feedCopiedInYourClipboard: 'Feed copiado en tu portapapeles',
  theCodesDoNotAllowSpacesOrSpecialCharacters:
    'Los códigos no admiten espacios ni caracteres especiales.',
  importPodcast: 'Importar podcast',
  importYourPodcast: 'Importa tu podcast',
  importText:
    'Importar tu podcast desde un feed es la forma más fácil de migrar tu podcast y todos sus episodios a Mumbler.',
  copyAndPasteYourRSSLink: 'Copia y pega tu enlace RSS',
  copyAndPasteYourRSSLinkText:
    'Copia el enlace del feed RSS de tu podcast en tu web original y pégalo en el campo que tienes aquí debajo.',
  pasteYourPodcastRSSFeedLinkHere:
    'Pega aquí el enlace del feed RSS de tu podcast',
  import: 'Importar',
  rssFeedLinkIsRequired: 'El enlace del feed RSS es necesario',
  importInProgress: 'Importación en proceso',
  thisMayTakeSomeTimePleaseComeBackLater:
    'Esto nos puede llevar un tiempo. Por favor vuelve más tarde.',
  accept: 'Aceptar',
  almostThere: 'Ya casi estamos',
  importSuccessMessage:
    'Ya hemos importado toda la información disponible del feed. Ahora edita el podcast para complementar lo que falta por favor.',
  finalStep: 'Último paso',
  finalStepDescription:
    'Para que los episodios que publiques a partir de ahora en Mumbler, se muestren en todas las plataformas, debes añadir el nuevo feed RSS en tu perfil de cada plataforma.',
  copyNewRSS: 'Copiar nuevo RSS',
  rssImportFailed: 'La importación del RSS ha fallado',
  rssImportFailedDescription:
    'No se ha podido completar la importación de tu podcast. Por favor contacta con nosotros  en el chat.',
  contact: 'Contactar',
  youAreAlreadyImportingAFeed: 'Ya estás importando un feed',
  youAreAlreadyImportingAFeedDescriprion:
    'No se pueden importar varios feeds al mismo tiempo. Por favor espera que acabe la importación en curso.',
  gotIt: 'Entendido',
  transcription: 'Transcripción',
  showTheTranscriptToAllUsers: 'Muestra la transcripción a todos los usuarios.',
  aiTranscription: 'Transcripción con IA',
  aiTranscripting: 'Transcribiendo',
  aiTranscribed: 'Transcribido',
  transcriptionFailed: 'La transcripción ha fallado',
  audioTranscriptionStartedSuccessfully:
    'La transcripción ha empezado. Puede que tarde un rato (un episodio de 1h suele tardar 10 min.). Mientras esperas, puedes abandonar esta página y volver más tarde.',
  aiTranscriptionCredits: 'Créditos de transcripción con IA',
  audioSizeError: 'Audio size error',
  audioSizeErrorDescription: 'El audio es demasiado largo para ser transcrito.',
  youConsumedOutOfTotal: ({ consumed, total }) =>
    `Has consumido ${consumed} minutos de un total de ${total} minutos incluidos en tu plan.`,
  subscriptionRenewalAndTranscriptionCreditsUpdate: ({ renewal }) =>
    `Tu suscripción se renueva el ${renewal} y tus créditos de transcripción se actualizarán.`,
  thisAudioWillConsume: ({ consumed }) =>
    `La transcripción de este audio consumirá ${consumed} minutos.`,
  areYouSureYouWantToTranscribeTheAudio:
    '¿Estás seguro de que quieres transcribir el audio?',
  youDontHaveEnoughCreditsAvailableDoYouWantToUpgradeYourPlan:
    'No tienes suficientes créditos disponibles. ¿Quieres actualizar tu plan?',
  continue: 'Continuar',
  update: 'Subir de plan',
  cancel: 'Cancelar',
  audioTranscriptionOnlyForPro:
    'La transcripción con IA está disponible para usuarios de los planes PRO y PLUS. Por favor suscríbete.',
  beforeTranscribingPublishEpisode:
    'Antes de iniciar la transcripción, por favor publica el episodio. De este modo nos aseguramos de que no pierdas tu información mientras se procesa la transcripción.',
  uniquePlays: 'Reproducciones únicas',
  playsByCountry: 'Reproducciones por país',
  playsByPlayer: 'Reproducciones por reproductor',
  playsByPlatform: 'Reproducciones por plataforma',
  playsByOS: 'Reproducciones por SO',
  listeners: 'Oyentes',
  listenersIsTheAverageNumberOfUniquePlaysInTheFirst24HoursOfTheLast3Episodes:
    'Oyentes es el promedio de reproducciones únicas en las primeras 24 horas de los últimos 3 episodios.',
  thirdParty: 'Otros',
  days: ({ days }) => `${days} días`,
  allTime: 'todo',
  onlyIncludesDataSince: 'Sólo incluye datos desde 10.06.24',
  viewGeneralMetrics: 'Ver métricas generales',
  creatingNewInsertion: 'Creando Nueva Inseción',
  editInsertion: 'Editar la inserción',
  whereToInsert: 'Dónde insertar',
  submit: 'Guardar',
  uploadAudio: 'Subir audio',
  audioInsertAtRequired: 'Por favor selecciona la posición de la inserción',
  audioInsertionCreateSuccess:
    'Tu campaña ya está configurada. Si quieres, la puedes desactivar en el listado de campañas.',
  inProgress: 'En progreso',
  failed: 'Fallo',
  scheduled: 'Programado',
  published: 'Publicado',
  audioInsertionDeleteMessage:
    'Estás a punto de borrar la campaña. Quieres seguir?',
  metricsNotAdd: 'Las métricas no incluyen los datos de Spotify.'
};
es.registerLogin = {
  resetYourPassword: 'Restablecer tu contraseña',
  dontForgetToUpdateItOnAllYourPodcastApps:
    'No te olvides de actualizarlo en todas tus Apps de podcasting',
  startEnjoyingThisPodcastWithStatus: ({ isForSubscriptionPurpose }) =>
    `Empieza a disfrutar el podcast ${isForSubscriptionPurpose}`,
  startEnjoyingThisAudioWithStatus: ({ isForSubscriptionPurpose }) =>
    `Empieza a disfrutar de este audio ${isForSubscriptionPurpose}`,
  startYourSubscriptionPodcast: 'Crea tu podcast de suscripción',
  startYourAudio: 'Crea tu audio',
  registerAndChoosePassword: 'Únete y elige la contraseña',
  start: 'Empezar',
  iDontWantDonationsIWantClientsPayingForMyEpisodes:
    'Cuando empiezas a usar Mumbler te das cuenta de que la plataforma ha sido creada por podcasters y para podcasters.',
  letsGo: 'Vamos allá!',
  youAreAlmostThere: 'Ya casi estás!',
  congratsYouHaveAlreadyCreatedYourMumblerAccountJustOneStepMoreToSuscribeToYourFirstPodcast:
    'Enhorabuena! Ya has creado tu cuenta en Mumbler. Solo un paso más para adquirir tu primer audio.',
  welcome: 'Hola',
  goInside: 'Entrar',
  recoverYourPassword: 'Recuperar tu contraseña',
  enterYourMailAndWeWillSendYouALinktoResetYourPassword:
    'Dinos tu dirección de email y te enviaremos un correo electrónico para crear una contraseña nueva',
  emailVerificationInProgress: 'Verificación del correo electrónico en proceso',
  pleaseWaitWhileWeVerifyYourEmail:
    'Por favor espera mientras verificamos tu correo electrónico',
  alreadyHaveAccount: 'Ya tienes cuenta? Entra',
};
es.sitemap = {
  subscribeCardDetails: 'Suscríbete - Información de tu tarjeta',
  podcatcher: 'Podcatcher',
  contact: 'Contacto',
  help: 'Ayuda',
  receipts: 'Recibos',
  creditCard: 'Tarjeta bancaria',
  publicPodcast: 'Podcast público',
  registerAsAPodcaster: 'Unirse como podcaster',
  registerAsAPodcasterStep2: 'Unirse como podcaster - Paso 2',
  podcasterLogin: 'Acceso para podcasters',
  'forgotPassword?': 'Olvidaste la contraseña?',
  editProfile: 'Editar el perfil',
  unsubscribe: 'Desuscribirte',
  myMumbler: 'Mi Mumbler',
  createNewPodcast: 'Crear un nuevo Podcast',
  singlePodcastDetail: 'Información del podcast',
  allEpisodes: 'Todos los episodios',
  singleEpisodeDetail: 'Información del episodio',
  newEpisode: 'Nuevo Episodio',
  home: 'Mumbler',
};
es.table = {
  name: 'Nombre',
  since: 'Contacto desde',
  sinceMonths: 'Meses como suscriptor',
  purchaseDate: 'Fecha de compra',
  status: 'Estado',
  pleaseAddEpisodesByClickingNewEpisodeButtonAbove:
    'Por favor añade nuevos episodios haciendo click en el botón Nuevo Episodio',
  pleaseAddAudioByClickingAddAudioButtonAbove:
    'Agregue audio haciendo clic en el botón Agregar audio arriba',
  noData: 'No hay datos',
  subscribed: 'Suscrito',
  unsubscribed: 'Desuscrito',
  unsubscribedAccess: 'Desuscrito con acceso',
  subscriptionType: 'Intervalo',
  monthly: 'Mensual',
  annual: 'Anual',
  used: 'Usado',
  actions: 'Acciones',
  edit: 'Editar',
  remove: 'Eliminar',
  getLink: 'Copiar link',
  date: 'Fecha',
  length: 'Duración',
  view: 'Ver',
  sentDate: 'Enviada',
  createDate: 'Creada',
  tags: 'Etiquetas',
  email: 'Email',
  opened: 'Abiertos',
  clicked: 'Clicks',
  openRate: 'Ratio de aperturas',
  clickThroughRate: 'CTR',
  views: 'Visitas',
  amount: 'Cantidad',
  sinceMonthsAsDonors: 'Meses como donantes',
  active: 'Activo',
  inactive: 'Inactivo',
  donationDate: 'Fecha donación',
  unsubscribeDate: 'Fecha de desuscripción',
  country: 'Country',
  downloads: 'Downloads',
  player: 'Player',
  percentageSymbol: '%',
  os: 'SO',
  episodeName: 'Episodio',
  uniquePlays: 'Reproducciones únicas',
  startDate: 'Fecha de inicio',
  finishDate: 'Fecha de fin',
  plays: 'Reproducciones',
};
es.team = {
  team: 'EQUIPO',
  meetOurTeam: 'Conoce a nuestro equipo',
  cofounder: 'Cofundador',
  designer: 'Diseñadora de producto',
  podcaster: 'Podcaster pro',
  pol: 'Podcaster en Planeta M, Tribucasters y Bootstrapeando un Saas. Emprendedor.',
  jose: 'Podcaster en En.Digital, Tribucasters y Bootstrapeando un Saas. Emprendedor.',
  victor:
    'Podcaster en No es asunto vuestro y Nordic Wire. Fundador de Guidedoc.tv.',
  carolina: 'Diseñadora de producto. ',
  weArePodcastersWhoWantToMakeALivingFromPodcastingAndHelpOthersToDoSo:
    'Somos podcasters. Queremos ganarnos la vida con nuestros podcasts y ayudarte a ti a hacer los mismo',
  ourAdvisors: 'Nuestros advisors',
  thoseWhoHelpToTakeMumblerToTheNextLevel:
    'Los que nos ayudan a llevar Mumbler a otro nivel',
  'wantToStartAPremiumPodcast?': 'Empieza tu podcast de suscripción',
};
es.privacyPolicy = {
  privacyPolicy: 'Política de privacidad de Mumbler',
  effectiveDate: 'Fecha de última actualización: 27 de octubre de 2020',
  welcomeMessage: '¡Bienvenido a Mumbler!',
  mumblerInfo: `Mumbler es una plataforma donde los oyentes pueden suscribirse a podcasts independientes. Esta Política de privacidad se aplica a los podcasters, oyentes y todos los usuarios de nuestra plataforma, y ​​es parte de nuestros Términos de uso.`,
  mumblerInfo2: `Mumbler es una empresa global. Al utilizar nuestra plataforma, aceptas que la información personal que nos proporcionas, o que recopilamos a través de su uso de la plataforma, puede ser transferida y almacenada en la UE y tratada como se describe en esta Política.`,
  preamble: 'Preámbulo',
  preambleInfo: `Mumbler, en cumplimiento de lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y Comercio Electrónico (en adelante LISSEC), comunica al Usuario, con carácter previo a la prestación de los servicios, los trámites y obligaciones requeridos para la ejecución del presente contrato:`,
  preamblePoint: `El Usuario se compromete a leer atentamente el contenido de las presentes Condiciones Generales y Política de Privacidad (en adelante las Condiciones).`,
  preamblePoint2: `El Usuario se compromete a introducir los datos personales y cualquier información adicional necesaria para la prestación del servicio.`,
  preambleInfo2: `Mumbler garantiza, mediante el contenido de las condiciones, el cumplimiento íntegro de la legislación de protección al consumidor aplicable con el fin de proteger los intereses legítimos de los usuarios durante todo el proceso de contratación de los productos, servicios, contenidos y / o herramientas ofrecidos a través del sitio web.`,
  informationCollected: `Información que proporciona a través de su cuenta`,
  informationCollectedInfo: `La información que nos proporciona a través de campos de texto, como su nombre, información de pago y beneficios. La información que recopilamos difiere dependiendo de si crea una cuenta, se convierte en un oyente o se convierte en un podcaster.`,
  informationCollectedPoint: `Nombre y apellido`,
  informationCollectedPoint2: `Dirección de correo electrónico`,
  informationCollectedPoint3: `Nombre de usuario`,
  informationCollectedPoint4: `Contraseña`,
  informationCollectedPoint5: `País de residencia.`,
  informationCollectedInfo2: `También puede registrarse con una cuenta de Facebook o Google. Solicitaremos permiso para acceder a información básica de su cuenta de Facebook o Google, como su nombre, correo electrónico e imagen de perfil. Puede optar por dejar de compartir esa información con nosotros en cualquier momento yendo a Facebook o Google para eliminar el acceso de Mumbler a esa cuenta y su capacidad para iniciar sesión.`,
  informationCollectedInfo3: `También tiene la opción de agregar más información a su perfil público, como una ubicación, enlaces de redes sociales y una sección acerca de, que almacenamos junto con cualquier comentario o mensaje que envíe en Mumbler.`,
  listeners: `Oyentes`,
  listenersInfo: `Un oyente es alguien que se une a la plataforma de membresía de Mumbler para pagar el contenido de la membresía de podcaster. Como oyente, debe proporcionar su información de pago a nuestros procesadores de pagos. Puede ver la política de privacidad de estos procesadores de pago en el sitio de Stripe. En este momento, Mumbler no recibe su número de tarjeta completo. Los procesadores de pagos nos proporcionan un token que representa su cuenta, la fecha de vencimiento de su tarjeta, el tipo de tarjeta y los últimos cuatro dígitos de su número de tarjeta. Si se le solicita que proporcione su nombre y dirección de correo electrónico al procesador de pagos, entonces también nos proporcionará esa información.`,
  listenersInfo2: `Recopilamos y procesamos información sobre los podcasters a los que está suscrito.`,
  podcasters: `Podcasters`,
  podcastersInfo: `Un podcaster es alguien que crea y proporciona contenido para sus oyentes a través de la plataforma de membresía de Mumbler. Para convertirse en un podcaster, debe crear una página que describa su podcast de membresía. Para recibir pagos, debe crear una cuenta. También podemos solicitar la información de su cuenta bancaria para procesar los pagos. También debe proporcionarnos información adicional a efectos fiscales. Dependiendo de su ubicación, es posible que deba completar un formulario con alguna combinación de sus:`,
  podcastersPoint: `Nombre y apellido`,
  podcastersPoint2: `Habla a`,
  podcastersPoint3: `Pais de Ciudadania`,
  podcastersPoint4: `Número de identificación fiscal extranjero`,
  podcastersPoint5: `Información de cuenta bancaria para pagos`,
  additionalInformationCollected: `Información adicional que recopilamos`,
  additionalInformationCollectedPoint: `Información recopilada automáticamente`,
  additionalInformationCollectedPointInfo: `Recopilamos información automáticamente mientras navega por el sitio o a través de nuestros proveedores de análisis externos. Podemos almacenar información de uso, como el tipo de dispositivo que usa para acceder a Mumbler, su sistema operativo, tipo de navegador, dirección IP e ID de dispositivo, las páginas que visita o solicita, enlaces en los que hace clic, sitios de referencia, interacciones del usuario y sus términos de búsqueda. . También derivamos su ubicación de su país auto-divulgado, su dirección IP y de su tarjeta de pago.`,
  additionalInformationCollectedPoint2: `Blogs`,
  additionalInformationCollectedPoint2Info: `Podemos recopilar y almacenar su dirección de correo electrónico, sus comentarios y la información de su perfil de nuestros sitios de blogs para comprender mejor sus intereses y mejorar nuestros servicios para usted.`,
  informationByElse: `Información recopilada por terceros`,
  cookies: `Cookies`,
  cookiesInfo: `Usamos cookies en nuestro sitio web que recopilan información para permitirnos a nosotros y a terceros comprender su uso de Mumbler. Consulte nuestra política de cookies para obtener más información sobre nuestro uso de cookies en Mumbler.`,
  usingInformation: `Cómo usamos su información`,
  usingInformationInfo: `Procesamos su información para:`,
  usingInformationPoint: `verificar su identidad para cumplir con las leyes internacionales`,
  usingInformationPoint2: `permitirle iniciar sesión en su cuenta`,
  usingInformationPoint3: `le permite apoyar el programa de membresía de un podcaster en Mumbler`,
  usingInformationPoint4: `procesar pagos y pagos de membresía`,
  usingInformationPoint5: `enviarle correos electrónicos relevantes para su uso, según lo controlen sus preferencias de correo electrónico`,
  usingInformationPoint6: `responde a tus preguntas`,
  usingInformationPoint7: `Brindarle servicios existentes y mejorados relacionados con su cuenta de Mumbler.`,
  usingInformationPoint8: `Destacar y celebrar podcasters`,
  usingInformationPoint9: `comprender cómo usa el servicio y crear mejores herramientas para que los podcasters sirvan a los oyentes`,
  usingInformationPoint10: `realizar investigación y desarrollo para mejorar Mumbler y desarrollar productos futuros`,
  usingInformationPoint11: `prevenir el fraude y el abuso en Mumbler`,
  usingInformationPoint12: `proporcionarle ajustes razonables, si nos notifica de una discapacidad`,
  sharingInformationWithPodcaster: `Información que compartimos con podcasters`,
  sharingInformationWithPodcasterInfo: `Al convertirse en un oyente de un podcaster, acepta que la siguiente información se comparta con ese podcaster:`,
  sharingInformationWithPodcasterPoint: `su nombre y dirección de correo electrónico, y otra información de perfil que haya proporcionado`,
  sharingInformationWithPodcasterPoint2: `cualquier mensaje que envíe a los podcasters a través de Mumbler`,
  sharingInformationWithPodcasterPoint3: `toda la información sobre su suscripción, incluida la cantidad y la fecha de inicio, pero no la información completa de su tarjeta de pago`,
  sharingInformationWithPodcasterPoint4: `algunos datos agregados y anonimizados sobre cómo usa Mumbler que no se pueden vincular a usted ni a ningún usuario individual`,
  sharingInformationWithElse: `Información que compartimos con terceros`,
  sharingInformationWithElseInfo: `Nunca vendemos su información a terceros. Solo compartiremos datos con terceros, que no sean podcasters, en las siguientes circunstancias:`,
  sharingInformationWithElsePoint: `con nuestros proveedores de servicios, que son empresas que están comprometidas contractualmente con nosotros para brindarnos servicios, como cumplimiento de pedidos, administración de correo electrónico, análisis de tendencias de datos, procesamiento de tarjetas de crédito, soluciones de liquidación de múltiples divisas, aumento de nuestra conciencia de marca y participación del usuario con marketing iniciativas y detección y prevención de fraudes. Estas empresas pueden tener acceso a sus datos para realizar sus servicios y están obligadas por contrato a salvaguardar cualquiera de sus datos que reciban de nosotros en la misma medida en que Mumbler los protege.`,
  sharingInformationWithElsePoint2: `para proteger la seguridad o integridad de Mumbler, y para proteger los derechos, la propiedad o la seguridad de Mumbler, sus empleados, usuarios u otros, si creemos que la divulgación es razonablemente necesaria para cumplir con una ley, reglamento u otra ley válida. proceso (por ejemplo, citaciones u órdenes judiciales entregadas a Mumbler). Si vamos a divulgar sus datos, haremos todo lo posible para notificarle rápidamente por correo electrónico, a menos que la ley nos prohíba hacerlo.`,
  sharingInformationWithElsePoint3: `en relación con la venta, fusión, quiebra, venta de activos o reorganización de nuestra empresa. Le notificaremos si una empresa diferente recibe sus datos. Las promesas de esta política de privacidad se aplican a cualquier dato transferido a una nueva entidad.`,
  informationCollectedFromElse: `Información que Mumbler recopila de aplicaciones de terceros`,
  informationCollectedFromElseInfo: `Cuando crea una cuenta de Mumbler, puede optar por conectar su (s) cuenta (s) de redes sociales (por ejemplo, Twitter, Facebook, Google, YouTube, Twitch) con Mumbler, y recopilaremos y almacenaremos cierta información social de esas plataformas, como:`,
  informationCollectedFromElsePoint: `conteos de seguidores o suscriptores,`,
  informationCollectedFromElsePoint2: `publicar o cargar recuentos,`,
  informationCollectedFromElsePoint3: `recuentos de vistas, me gusta y comentarios.`,
  informationCollectedFromElseInfo2: `Esta información social nos permite brindarle una mejor experiencia de Mumbler y también ayuda a guiar el desarrollo futuro de Mumbler. Usamos estos datos para:`,
  informationCollectedFromElsePoint4: `ayudar a los podcasters y a los oyentes a encontrarse en Mumbler`,
  informationCollectedFromElsePoint5: `evaluar cómo hacer que los podcasters tengan más éxito en Mumbler`,
  informationCollectedFromElsePoint6: `analizar y describir nuestro negocio`,
  preferenceAndRights: `Sus preferencias y derechos sobre los datos`,
  choosingPreference: `Elección de sus preferencias`,
  choosingPreferenceInfo: `El enlace Configuración se encuentra al hacer clic en su avatar o perfil en la parte superior derecha de su pantalla, después de iniciar sesión en su cuenta Mumbler. La configuración le permite ver las preferencias de su cuenta. Puede ver y ajustar su configuración viendo sus preferencias y, si lo desea, cambiando sus selecciones.`,
  marketingActivities: `Actividades de marketing`,
  marketingActivitiesInfo: `Al aceptar nuestros términos de uso, acepta expresamente que Mumbler puede:`,
  marketingActivitiesPoint: `Brindarle información sobre su servicio, mejoras de servicio o nuevos productos Mumbler, mientras está en nuestro sitio web o utiliza nuestras aplicaciones.`,
  marketingActivitiesPoint2: `enviarle mensajes con respecto a su servicio existente, o mejoras relacionadas con su servicio existente, cuando esté fuera de nuestra plataforma, por correo electrónico o por mensaje de texto, si se ha suscrito a un servicio o notificación de evento que utiliza mensajes de texto.`,
  marketingActivitiesPoint3: `enviarle mensajes relacionados con los servicios que brindamos, que son independientes de su servicio existente, por correo electrónico, del cual puede optar por no participar.`,
  marketingActivitiesPoint4: `comercializar Mumbler para usted y audiencias similares a usted en función de sus redes y factores comunes que otros tienen con usted, a menos que opte por no participar`,
  marketingActivitiesPoint5: `enviarle correos electrónicos o mensajes de texto de marketing si no tiene una cuenta de Mumbler pero ha dado su consentimiento para recibir dichos mensajes, de los cuales puede optar por no recibirlos.`,
  marketingActivitiesPoint6: `solicitar información demográfica para destacar y celebrar los podcasters`,
  marketingActivitiesPoint7: `promocione su cuenta para una mayor visibilidad`,
  optOutOfMarketing: `Opt-out`,
  optOutOfMarketingInfo: `Puede oponerse al uso de sus datos con fines de marketing en cualquier momento, sin cargo, cuando recopilamos su información personal y en cada comunicación de marketing. También puede oponerse al uso de sus datos para fines comerciales al crear su cuenta o en cualquier momento posterior.`,
  optOutOfMarketingInfo2: `La exclusión voluntaria detendrá los correos electrónicos de marketing. Espere hasta 30 días para que se procese su solicitud de exclusión voluntaria. Si tiene una cuenta con Mumbler, continuará recibiendo mensajes de texto y correos electrónicos relacionados con el servicio, si ha optado por recibir mensajes de texto. También continuará recibiendo envíos de beneficios relacionados con el servicio a la dirección de entrega designada.`,
  optOutOfMarketingInfo3: `Nunca venderemos su información a terceros con fines de marketing sin su consentimiento previo.`,
  accountInformation: `Acceder, actualizar o exportar la información de su cuenta`,
  accountInformationInfo: `Puede acceder y actualizar cierta información asociada con su cuenta en las páginas de configuración. Además, puede eliminar su cuenta o exportar su información personal.`,
  deletingAccount: `Eliminar su cuenta`,
  deletingAccountInfo: `Puede iniciar automáticamente una eliminación de su cuenta. Tenga en cuenta que este es un acto final y no puede revertirse. Si desea transportar sus datos, asegúrese de descargarlos primero, antes de eliminar su cuenta.`,
  turnOffEmails: `Desactivar las notificaciones por correo electrónico`,
  turnOffEmailsInfo: `Puede cambiar sus notificaciones por correo electrónico en la configuración de su correo electrónico. Si bien esto le permite detener muchos correos electrónicos de notificación, seguiremos enviando algunos correos electrónicos de servicio críticos.`,
  verificationRequests: `Verificación de solicitudes`,
  verificationRequestsInfo: `Los usuarios mantienen cuentas protegidas con contraseña con Mumbler. Si envía una solicitud para ejercer sus derechos de privacidad, se le pedirá que verifique la solicitud iniciando sesión en su cuenta de Mumbler.`,
  verificationRequestsInfo2: `Si no podemos verificar su solicitud, entonces, para su protección, nos reservamos el derecho de rechazar la solicitud.`,
  complianceWithLaw: `Cumplimiento de las leyes de privacidad de la UE y el Privacy Shield`,
  complianceWithLawInfo: `Mumbler es una empresa internacional que cumple con el Reglamento General de Protección de Datos (GDPR). Actuamos como Controladores de datos, según se define en el GDPR, y procesamos los datos personales en función de nuestros intereses legítimos y por necesidad contractual al brindarle el Servicio como se describe en "Cómo usamos su información". También procesamos y compartimos información personal en función de la notificación y el consentimiento de los usuarios, que nuestros usuarios pueden revocar en cualquier momento.`,
  dataRights: `Ejercicio de sus derechos sobre los datos`,
  dataRightsInfo: `Los usuarios tienen derechos en virtud del Reglamento general de protección de datos (GDPR) con respecto a los datos que Mumbler controla como controlador de datos según se define en el GDPR, que incluyen:`,
  dataRightsPoint: `el derecho de acceso a sus datos personales;`,
  dataRightsPoint2: `el derecho a corregir o rectificar cualquier dato personal inexacto;`,
  dataRightsPoint3: `el derecho a restringir u oponerse al procesamiento de datos personales;`,
  dataRightsPoint4: `el derecho a borrar o eliminar sus datos personales;`,
  dataRightsPoint5: `el derecho a la portabilidad de los datos personales; y`,
  dataRightsPoint6: `el derecho a optar por no participar en la venta de información personal.`,
  dataRightsInfo2: `Puede ejercer los derechos sobre sus datos en Mumbler de las siguientes formas:`,
  dataRightsPoint7: `acceder, revisar, modificar y actualizar sus datos iniciando sesión en su cuenta en Mumbler y yendo a la configuración de su cuenta.`,
  dataRightsPoint8: `optar por no participar en el marketing.`,
  dataRightsPoint9: `ir al soporte de preguntas frecuentes en línea.`,
  dataRightsPoint10: `Si no puede iniciar sesión en su cuenta y no puede recuperar su cuenta con un restablecimiento de contraseña para presentar su solicitud de privacidad, puede comunicarse con privacy@mumbler.com. Nos reservamos el derecho de negarle el acceso o la recuperación de su cuenta, a nuestra discreción, para evitar una toma de control no autorizada de su cuenta.`,
  discriminationProhibition: `Prohibición contra la discriminación`,
  discriminationProhibitionInfo: `No discriminamos a los usuarios que ejercen sus derechos de privacidad.`,
  dataRetention: `Nuestro período de retención de datos`,
  dataRetentionInfo: `Conservamos la información de su cuenta durante diez años después de que su cuenta esté activa por última vez, a menos que elimine o solicite que eliminemos su cuenta. Es posible que sigamos reteniendo cierta información, incluso después de que elimine su cuenta, si estamos obligados a hacerlo para cumplir con diversas leyes.`,
  security: `Seguridad`,
  securityInfo: `La seguridad de su información personal es importante para nosotros y seguimos los estándares de la industria para protegerla.`,
  children: `Niños`,
  childrenInfo: `Mumbler no está dirigido a niños menores de 13 años, y los niños no pueden crear una cuenta o usar Mumbler de otra manera.`,
  changes: `Cambios`,
  changesInfo: `A veces podemos hacer cambios a esta política. Si realizamos cambios materiales que afecten negativamente sus derechos en virtud de esta política, se lo haremos saber publicando un anuncio en el sitio o enviándole un correo electrónico antes de que los cambios entren en vigencia. Continuar usando Mumbler después de un cambio en esta política significa que acepta la nueva política.`,
  termsOfUse: `Condiciones de uso`,
  termsOfUseInfo: `Mumbler es una plataforma de membresía que permite que sus oyentes paguen a los podcasters. Uno de nuestros comportamientos principales es poner a los podcasters en primer lugar, y estos términos intentan hacerlo.`,
  mumblerInfo3: `Al utilizar Mumbler, acepta estos términos y las demás políticas que publicamos. Léalos detenidamente y háganos saber si tiene alguna pregunta. Para obtener información sobre nuestras prácticas de datos, consulte nuestra Política de privacidad, incluida nuestra Política de cookies. Podemos recopilar y utilizar su información de acuerdo con esas políticas.`,
  yourAccount: `Su cuenta`,
  yourAccountInfo: `Cuando crea una cuenta, debe proporcionarnos información precisa, de buena fe, y acepta mantener su información actualizada si cambia.`,
  yourAccountInfo2: `Para crear una cuenta debe tener al menos 13 años. Para unirse a la membresía de un podcaster como oyente, o proporcionar una membresía como podcaster, debe tener al menos 18 años o tener el permiso de sus padres.`,
  yourAccountInfo3: `Usted es responsable de todo lo que ocurra cuando alguien inicia sesión en su cuenta, así como de la seguridad de la cuenta. Comuníquese con nosotros de inmediato si cree que su cuenta está comprometida. Puede obtener más información sobre la seguridad en nuestra página de Política de seguridad.`,
  abusiveConduct: `Conducta abusiva`,
  abusiveConductInfo: `Eres responsable de toda la actividad de tu cuenta. Si viola nuestras políticas, podemos cancelar su cuenta.`,
  abusiveConductInfo2: `No haga nada ilegal, abusivo hacia los demás o que abuse de nuestro sitio de manera técnica. Si es un podcaster que recauda fondos en Mumbler, es posible que seamos responsables de lo que haga con esos fondos. Como resultado, también analizamos lo que hace con su membresía fuera de nuestra plataforma.`,
  abusiveConductInfo3: `Estas políticas cubren la mayoría de los problemas, pero si encuentra una forma nueva y creativa de dañar a Mumbler o nuestra comunidad, podemos tomar medidas para prevenirlo.`,
  aboutBeingPodcaster: `Todo sobre ser un podcaster`,
  aboutBeingPodcasterInfo: `Un podcaster es alguien que ofrece membresía a los oyentes de Mumbler. Aquí hay muchos detalles relacionados con pagos, tarifas, impuestos y restricciones que debe leer en su totalidad si es un podcaster.`,
  membership: `Afiliación`,
  membershipInfo: `Para convertirse en un podcaster, simplemente abra su página para comenzar su membresía. Las membresías son para sus oyentes más apasionados. Los está invitando a ser parte de algo emocionante y los oyentes pagan por suscripción.`,
  payments: `Pagos`,
  paymentsInfo: `Como podcaster, su membresía está disponible en Mumbler, y proporcionamos membresía a sus oyentes por suscripción. También manejamos problemas de pagos como fraude, devoluciones de cargo y resolución de disputas de pagos.`,
  paymentsInfo2: `Tratamos de brindarle acceso oportuno a sus fondos, pero es posible que ocasionalmente experimente demoras en el acceso a sus fondos. También podemos bloquear o retener pagos por infracciones de nuestras políticas o por motivos de cumplimiento, incluida la recopilación de información de declaración de impuestos. Cuando los pagos se retrasan o bloquean, intentamos comunicarle el motivo de inmediato. Si tiene preguntas sobre un bloqueo de pagos, comuníquese con nosotros. Para proteger a los podcasters, podemos bloquear los pagos de los oyentes si creemos que son fraudulentos.`,
  paymentsInfo3: `A veces, actividades como los reembolsos pueden poner el saldo de su cuenta en negativo. Si su saldo se vuelve negativo, nos reservamos el derecho a recuperar esos fondos de pagos futuros.`,
  fees: `Tarifa`,
  feesInfo: `
Mumbler se queda una pequeña comisión de los pagos realizados por tus suscriptores. La comisión total que aplicamos es de un 10% + 30 céntimos por transacción (por cada pago que recibes). Esta comisión lo incluye todo:`,
  feesPoint: `Los costes de transacción: incluye las comisiones de la pasarela de pago, las comisiones de las tarjetas internacionales, las comisiones por reintento de cobro, las comisiones por cambio de tarjeta del suscriptor cuando esta caduca, la lucha contra el fraude…`,
  feesPoint2: `La comisión de Mumbler: con la que pagamos los servidores, mejoramos el producto, damos soporte técnico, pagamos al equipo…`,
  tax: `Impuesto`,
  taxInfo: `Recopilamos información de identificación fiscal y la informamos a las autoridades fiscales según lo requiera la ley. Usted es responsable de informar los impuestos sobre ingresos o retenciones que puedan adeudarse como resultado de los pagos recibidos.`,
  taxInfo2: `Además, somos responsables de una serie de impuestos sobre transacciones en todo el mundo. Estos incluyen, entre otros, el IVA sobre los pagos por servicios prestados electrónicamente a los oyentes en la UE, GST como operador de una plataforma de distribución electrónica (EDP) en Australia e impuestos estatales sobre las ventas como resultado de las 'leyes del mercado' en los Estados Unidos. Estados. A los efectos de los servicios suministrados electrónicamente, los podcasters nos proporcionan esos servicios y luego se los proporcionamos al oyente.`,
  taxInfo3: `En los lugares donde se requiere que Mumbler cobre el impuesto sobre las transacciones de los oyentes, las promesas procesadas de los oyentes reflejarán el nivel o la cantidad de la promesa personalizada más el impuesto aplicable. El impuesto aplicable no se deducirá de un compromiso, sino que se cobrará además del compromiso. Una vez liquidado, el monto del impuesto se deducirá automáticamente para su remisión a la autoridad fiscal correspondiente.`,
  taxInfo4: `Como podcaster en nuestro sitio, es su responsabilidad proporcionar información precisa cuando se relaciona con la herramienta de ponderación de impuestos sobre las ventas y, en general, cuando enumera los beneficios transferidos dentro de sus niveles.`,
  restrictions: `Restricciones`,
  restrictionsInfo: `No permitimos contenido que viole nuestras políticas. Aquí tienes un resumen de nuestras reglas:`,
  restrictionsPoints: `Contenido ilegal.`,
  restrictionsPoints2: `Contenido abusivo hacia otras personas.`,
  restrictionsPoints3: `Contenido que utilice la propiedad intelectual de otros, a menos que tenga un permiso por escrito para usarlo, o su uso esté protegido por un uso justo.`,
  restrictionsInfo2: `Si sus oyentes incluyen personas menores de 18 años, recuérdeles que necesitan permiso para unirse a su membresía y que los menores de 13 años no pueden usar Mumbler. No estamos obligados a permitir que ninguna persona o grupo de personas en particular sea su oyente.`,
  restrictionsInfo3: `Como podcaster, también es responsable de mantener seguros los datos de los oyentes.`,
  restrictionsInfo4: `Una cuenta está vinculada a su producción creativa y no se puede vender ni transferir para que la use otro podcaster.`,
  aboutBeingListener: `Todo sobre ser un oyente`,
  aboutBeingListenerInfo: `Para convertirse en un oyente, simplemente cree una cuenta, agregue sus detalles de pago y únase a una membresía de podcaster.`,
  aboutBeingListenerInfo2: `La cantidad de cada suscripción de membresía depende del podcaster que admita. Puede ver los detalles a medida que se une, así como en el recibo que se le envía después de cada pago exitoso. Puedes ver todas tus suscripciones de membresía activas y el historial de facturación en tu página "my Mumbler".`,
  aboutBeingListenerInfo3: `Puede cancelar los pagos de su membresía en cualquier momento. La terminación del soporte de nivel de membresía entrará en vigencia en el plazo de pago posterior (no actual).`,
  aboutBeingListenerInfo4: `Cancelar su pago puede resultar en la pérdida de acceso al podcast de un podcast.`,
  aboutBeingListenerInfo5: `Estos incluyen cuando cancela su suscripción de membresía, su método de pago falla, el podcaster lo bloquea o el podcaster elimina su cuenta. No estamos obligados a permitirle escuchar ningún podcaster en particular.`,
  aboutBeingListenerInfo6: `Las membresías de los podcasters varían y tenemos un control limitado sobre la calidad y las ofertas específicas. Intentamos detectar páginas fraudulentas de podcaster, pero no podemos garantizar la identidad de los podcasters o la validez de las afirmaciones que hacen. Agradecemos su ayuda para informar sobre páginas de podcaster sospechosas para que podamos mantener a Mumbler a salvo.`,
  aboutBeingListenerInfo7: `Si se encuentra en la jurisdicción en la que Mumbler debe cobrar y cobrar impuestos (por ejemplo, IVA o impuesto sobre las ventas), este impuesto se agrega al cargo total. Mumbler remite todos los impuestos recaudados a la autoridad fiscal correspondiente. Como los impuestos dependen en gran medida de su ubicación, usted es responsable de mantener su dirección completa y actualizada.`,
  aboutBeingListenerInfo8: `Dependiendo de su ubicación, algunos bancos pueden cobrarle una tarifa de transacción extranjera por su suscripción de membresía. Mumbler no controla esta carga, pero suele rondar el 3%. Comuníquese con su banco para obtener más información.`,
  refunds: `Reembolsos`,
  aboutBeingListenerInfo9: `. Nuestra política es No reembolsos, aunque permitiremos algunas excepciones en las que los reembolsos se otorgan a nuestro exclusivo criterio.`,
  mumblerRole: `El papel de Mumbler`,
  mumblerRoleInfo: `Analizamos de manera proactiva algunas páginas y publicaciones en Mumbler para asegurarnos de que los podcasters sigan nuestros términos y condiciones. También investigamos informes de posibles infracciones. Es posible que estas investigaciones tarden un tiempo en resolverse y pueden incluir el análisis de los fondos que se reciben a través de Mumbler.`,
  mumblerRoleInfo2: `En la mayoría de situaciones, trabajaremos con podcasters para resolver cualquier posible infracción y permitir que el podcaster continúe usando Mumbler. La cancelación de cuentas no es una acción que tomamos a la ligera y se realiza solo en los casos más extremos.`,
  mumblerRoleInfo3: `Háganos saber si ve posibles violaciones de nuestros Términos y condiciones.`,
  mumblerRoleInfo4: `Como empresa global con sede en España con operaciones en otros países, debemos cumplir con sanciones económicas y restricciones comerciales.`,
  mumblerRoleInfo5: `Estamos probando constantemente nuevas funciones con el objetivo de mejorar Mumbler. Podemos agregar o eliminar funciones y, a menudo, probar funciones con un subconjunto aleatorio de nuestra comunidad. Si creemos que una característica es significativamente diferente de estos términos, explicamos esas diferencias en la prueba.`,
  mumblerRoleInfo6: `Con su permiso, podemos otorgar a otros sitios web o servicios la capacidad de verificar información sobre su cuenta de Mumbler o realizar acciones en su nombre. Este permiso se solicita cuando conecta su cuenta de Mumbler a estos otros sitios web o servicios. Puede obtener más información en nuestra Política de privacidad.`,
  accountDeletion: `Eliminación de cuenta`,
  accountDeletionInfo: `Puede eliminar permanentemente su cuenta en cualquier momento.`,
  accountDeletionInfo2: `Podemos cancelar o suspender su cuenta en cualquier momento a nuestra discreción. También podemos cancelar cualquier suscripción de membresía y eliminar cualquier descripción, publicación o beneficio a nuestra discreción.`,
  accountDeletionInfo3: `No puede presentar un reclamo contra nosotros por suspender o cancelar la cuenta de otra persona, y acepta que no presentará dicho reclamo. Si intenta presentar un reclamo de este tipo, usted es responsable de los daños causados, incluidos los honorarios y costos de abogados.`,
  accountDeletionInfo4: `Estos términos permanecen vigentes incluso si ya no tiene una cuenta.`,
  yourCreation: `Tus creaciones`,
  yourCreationInfo: `Al publicar creaciones en Mumbler, nos otorga una licencia mundial libre de regalías, perpetua, irrevocable, no exclusiva, sublicenciable para usar, reproducir, distribuir, ejecutar, exhibir públicamente o preparar trabajos derivados de su creación. El propósito de esta licencia está estrictamente limitado para permitirnos proporcionar y promover membresías a sus oyentes. Nunca intentaremos robar sus creaciones o utilizarlas de forma explotadora.`,
  yourCreationInfo2: `No puede publicar creaciones que infrinjan la propiedad intelectual o los derechos de propiedad de otros.`,
  yourCreationInfo3: `los oyentes no pueden usar creaciones publicadas por podcasters de ninguna manera no autorizada por el podcaster.`,
  mumblerCreation: `Creaciones de Mumbler`,
  mumblerCreationInfo: `Nuestras creaciones están protegidas por leyes de derechos de autor, marcas registradas y secretos comerciales. Algunos ejemplos de nuestras creaciones son el texto del sitio, nuestro logotipo y nuestro código base. Le otorgamos una licencia para usar nuestro logotipo y otros derechos de autor o marcas comerciales para promocionar su página de Mumbler.`,
  mumblerCreationInfo2: `No puede utilizar, reproducir, distribuir, realizar, exhibir públicamente o preparar trabajos derivados de nuestras creaciones de otro modo a menos que le demos permiso por escrito. Por favor pregunta si tienes alguna pregunta.`,
  indemnity: `Indemnidad`,
  indemnityInfo: `Nos indemnizará de todas las pérdidas y responsabilidades, incluidos los honorarios legales, que surjan de estos términos o se relacionen con su uso de Mumbler. Nos reservamos el derecho al control exclusivo sobre la defensa de un reclamo cubierto por esta cláusula. Si usamos este derecho, nos ayudará en nuestra defensa.`,
  indemnityInfo2: `Su obligación de indemnizar bajo esta cláusula también se aplica a nuestras subsidiarias, afiliadas, funcionarios, directores, empleados, agentes y proveedores de servicios externos.`,
  warranty: `Renuncia a la garantía`,
  warrantyInfo: `Hacemos todo lo posible para asegurarnos de que Mumbler funcione como se espera, pero suceden cosas.`,
  warrantyInfo2: `Mumbler se proporciona "tal cual" y sin garantía de ningún tipo. Cualquier garantía de comerciabilidad, idoneidad para un propósito particular, no infracción y cualquier otra garantía está excluida en la mayor medida permitida por la ley.`,
  warrantyInfo3: `Las renuncias de garantía bajo esta cláusula también se aplican a nuestras subsidiarias, afiliadas y proveedores de servicios externos.`,
  liability: `Límite de responsabilidad`,
  liabilityInfo: `En la medida en que lo permita la ley, no somos responsables ante usted de ningún daño incidental, consecuente o punitivo que surja de estos términos, o de su uso o intento de uso de Mumbler. En la medida en que lo permita la ley, nuestra responsabilidad por daños se limita a la cantidad de dinero que hemos ganado a través de su uso de Mumbler. Específicamente, no somos responsables de las pérdidas asociadas con los beneficios incumplidos y de las pérdidas causadas por acuerdos contractuales en conflicto.`,
  liabilityInfo2: `Para esta cláusula, "nosotros" y "nuestro" se definen para incluir a nuestras subsidiarias, afiliadas, funcionarios, directores, empleados, agentes y proveedores de servicios externos.`,
  dispute: `Resolución de conflictos`,
  disputeInfo: `Para resumir: si tiene un problema, comuníquese con nosotros, pero tiene limitaciones para resolver las disputas. Usted renuncia a su derecho a un juicio por jurado y su derecho a participar en un procedimiento de acción de clase.`,
  disputeInfo2: `Le recomendamos que se comunique con nosotros si tiene algún problema. Si surge una disputa de estos términos o relacionada con su uso de Mumbler, y no se puede resolver después de que hable con nosotros, entonces debe resolverse mediante arbitraje.`,
  disputeInfo3: `Esta cláusula no limita la capacidad de cualquiera de las partes para presentar una acción en un tribunal con jurisdicción para buscar medidas cautelares u otra reparación equitativa por disputas relacionadas con la propiedad intelectual, datos de propiedad o para hacer cumplir esta cláusula de resolución de disputas, incluido su acuerdo de no hacer valer reclamaciones relacionadas con la suspensión o cancelación de la cuenta de otra persona. En cualquier acción de este tipo, el tribunal, en lugar de un árbitro, debe decidir si dicho reclamo es arbitrable y debe decidir si la parte tiene derecho a la medida cautelar solicitada u otra reparación equitativa.`,
  governingLaw: `Legislación aplicable`,
  governingLawInfo: `La ley española, excluyendo sus disposiciones sobre conflicto de leyes, rige estos términos y todas las demás políticas de Mumbler. Si surge una demanda, ambas partes dan su consentimiento a la jurisdicción y competencia exclusivas de los tribunales ubicados en Madrid, España.`,
  everythingElse: `Todo lo demás`,
  everythingElseInfo: `Estos términos y las políticas a las que se hace referencia son el acuerdo completo entre usted y nosotros, y reemplazan todos los acuerdos anteriores. Si alguna disposición de estos términos se considera inaplicable, dicha disposición se modifica en la medida necesaria para hacerla cumplir. Si una disposición no se puede modificar, se separa de estos términos y todas las demás disposiciones siguen vigentes. Si alguna de las partes no hace cumplir un derecho proporcionado por estos términos, no renuncia a la capacidad de hacer cumplir ningún derecho en el futuro.`,
  everythingElseInfo2: `En ocasiones, podemos realizar cambios en estos términos y políticas. Si realizamos cambios materiales que afecten negativamente a sus derechos, se lo haremos saber antes de que entren en vigor. Continuar usando Mumbler después de un cambio significa que acepta los nuevos términos o políticas.`,
  everythingElseInfo3: `Si utiliza herramientas de accesibilidad y tiene preguntas o inquietudes, comuníquese con nosotros.`,
  cookiePolicy: `Política de cookies`,
  cookiePolicyInfo: `Una cookie es un pequeño archivo de texto que un sitio web guarda en su computadora o dispositivo móvil cuando visita el sitio. Este pequeño archivo de texto permite que un sitio recuerde sus acciones y preferencias durante un período de tiempo específico, por lo que no tiene que volver a ingresar su información de inicio de sesión o configuración cada vez que visita una nueva página en nuestro sitio.`,
  useOnMumbler: `Su uso en Mumbler`,
  useOnMumblerInfo: `Las cookies no se utilizan para identificarle personalmente y no se utilizan para ningún otro propósito que no sea el descrito aquí.`,
  securityAndAuth: `Seguridad / Autenticación:`,
  securityAndAuthInfo: `Algunas funciones de cookies y tecnologías similares son necesarias y vitales para garantizar que`,
  securityAndAuthInfo2: `Mumbler funciona correctamente para visitantes y miembros, como mantener la seguridad, la protección y la integridad en Mumbler, la autenticación y el inicio de sesión (incluido recordar los permisos y consentimientos que ha otorgado) y garantizar la capacidad de completar transacciones de forma segura.`,
  performance: `Rendimiento / Análisis e investigación:`,
  performanceInfo: `Algunas tecnologías ayudan a proporcionar datos de rendimiento sobre el funcionamiento de Mumbler para realizar mejoras en la plataforma. Los datos recopilados en el sitio y la funcionalidad y velocidad de la aplicación, cómo se usa Mumbler y la detección y recopilación de informes sobre errores ayudan a mejorar Mumbler y los servicios que ofrecemos.`,
  preference: `Preferencias / Localización:`,
  preferenceInfo: `Las cookies permiten sus vistas y configuraciones personalizadas. Algunas cookies nos ayudan a brindar experiencias localizadas, por ejemplo, asegurándose de que vea Mumbler en su idioma preferido.`,
  socialNetworks: `Redes sociales / Marketing:`,
  socialNetworksInfo: `Algunas tecnologías le ayudan a interactuar con las redes sociales en las que ha iniciado sesión mientras usa Mumbler, como iniciar sesión con la red social y otras funciones que emplea con la red social, o que están permitidas en la política de privacidad de la red social. Estos pueden ser establecidos y controlados por las redes sociales, y sus preferencias con esas redes sociales.`,
  doNotTrack: `Tecnologías de "no seguimiento"`,
  doNotTrackInfo: `No respondemos a las señales de "No rastrear" del navegador web.`,
  manageCookie: `Cómo administrar las cookies`,
  manageCookieInfo: `Puede controlar el uso de cookies a nivel de navegador individual. Si rechaza o elimina las cookies, es posible que nuestros Servicios ya no funcionen según lo previsto. Cada navegador proporciona diferentes mecanismos para administrar las cookies. Consulte el menú de ayuda de su navegador para determinar la mejor manera de modificar el almacenamiento de cookies de su navegador.`,
  changesToCookiePolicy: `Cambios en la política de cookies`,
  changesToCookiePolicyInfo: `Actualizamos periódicamente esta Política de cookies para tener en cuenta los cambios en las circunstancias legales y operativas, para describir nuevas cookies y tecnologías de seguimiento, y para describir cómo estos cambios afectan nuestro uso de su información. Si vamos a utilizar la información de una manera materialmente diferente a la indicada en el momento de la recopilación o si vamos a realizar cambios sustanciales en nuestras prácticas de privacidad, se lo notificaremos. Publicaremos esos cambios mediante un aviso destacado en nuestro sitio web.`,
  changesToCookiePolicyInfo2: `Esta política es parte de los Términos de uso y la Política de privacidad de Mumbler. Para obtener más información, comuníquese con Mumbler.`,
};
es.podcatcher = {
  listenPodcatcher: 'Cómo escucharlo en tu podcatcher',
  copyRSSLink: 'Copiar Link RSS Público',
  copyPrivateRSSLink: 'Copiar enlace al RSS privado',
  copied: 'Copiado!',
  apple:
    'Para escuchar el podcast en Apple Podcast accede a “Librería / Editar / Añadir podcast con URL” y añade el feed privado.',
  overcast:
    'Para escuchar el podcast en Overcast accede a: “ + / ADD URL” y añade el feed RSS privado.',
  pocketCasts:
    'Para escuchar el podcast en Pocket Casts accede a “Icono de la lupa > Pegar el feed > Hacer clic en el podcast > Suscribirse”.',
  google:
    'Para escuchar el podcast en Google Podcast ve a: "Home / Más / Icono del enlace / Pega el feed RSS privado".',
  podcastGuruDescription:
    'Para escuchar el podcast en PodcastGuru accede a "Menú principal > Herramientas > Importar podcast de RSS > Pegar RSS"',
  PodcastAddictDescription:
    'Para escuchar el podcast en PodcastAddict accede a "El icono más en el menús superior > Lupa en el menú superior > Pegar el feed > Indicar que es un feed privado en el popup"',
};
es.faq = {
  faq1: `Si creo un podcast en Mumbler, ¿todo mi contenido estará cerrado para suscriptores?`,
  faq1Info: `Mumbler está pensado para que puedas monetizar tu audiencia. Pero sabemos que es interesante compartir parte del contenido de forma pública. Por este motivo, en Mumbler te permitimos crear `,
  faq1InfoBold: `3 tipos distintos de episodios`,
  faq1Info2: `, que tendrán distinta visibilidad:`,
  faq1PointBold: `Episodios privados:`,
  faq1Point: ` únicamente accesibles para tus suscriptores.`,
  faq1Point2Bold: `Episodio trailer:`,
  faq1Point2: ` destacado en la página de tu podcast en Mumbler para que cualquiera pueda saber de qué va tu podcast y qué esperar si se suscribe.`,
  faq1Point3Bold: `Episodios públicos:`,
  faq1Point3: ` pueden ser escuchados por cualquier visitante a tu página en Mumbler y en los podcatchers en los que añadas tu RSS público.`,
  faq1Info3: `Para mantener la esencia de Mumbler, que está orientada a crear podcasts de pago, solo un 50% de tus episodios pueden ser públicos. Si ya has publicado un 50% de episodios públicos, tendrás que crear un nuevo episodio privado antes de poder subir uno público.`,
  faq2: `¿Puedo crear varios podcasts dentro de la misma cuenta de Mumbler?`,
  faq2Info: `Sí, no hay límites en el número de podcasts que puedes crear dentro de Mumbler. La creación de un podcast en Mumbler tampoco tiene coste, así que sientete libre de crear tantos podcasts como quieras.`,
  faq3: `¿Cómo recibo el dinero que gano con los suscriptores de mis podcast?`,
  faq3Info: `En el panel de control de tu podcast verás el dinero que has ido acumulando gracias a los pagos de tus suscriptores. Cuando superes los `,
  faq3Bold: `50€ acumulados`,
  faq3Info2: `, podrás pedir que te enviemos tu dinero. Para ello, solo tienes que hacer click en el botón “Pedir pago” y en unos días recibirás el dinero. Solo puedes realizar `,
  faq3Bold2: `una petición de pago al mes`,
  faq3Info3: ` (por ahora).`,
  faq3Info4: `El límite de 50€ es necesario para minimizar el impacto de las comisiones de transferencia de dinero. Si la cantidad fuera menor, tendríamos que incrementar la comisión que aplicamos para hacer frente a la mayor comisión por la transferencia.`,
  faq4: `¿Cómo gana dinero Mumbler?`,
  faq4Info: `Mumbler se queda una pequeña comisión de los pagos realizados por tus suscriptores. La comisión total que aplicamos es de un 10% + 30 céntimos por transacción (por cada pago que recibes). Esta comisión lo incluye todo:`,
  faq4PointBold: `Los costes de transacción:`,
  faq4Point: ` incluye las comisiones de la pasarela de pago, las comisiones de las tarjetas internacionales, las comisiones por reintento de cobro, las comisiones por cambio de tarjeta del suscriptor cuando esta caduca, la lucha contra el fraude…`,
  faq4Point2Bold: `La comisión de Mumbler:`,
  faq4Point2: ` con la que pagamos los servidores, mejoramos el producto, damos soporte técnico, pagamos al equipo…`,
  faq5: `Si algún día quiero irme de Mumbler, ¿me puedo llevar mis suscriptores? ¿qué pasa con el feed público?`,
  faq5Info: `Los suscriptores y los contenidos `,
  faq5Bold: `son tuyos.`,
  faq5Info2: ` Si algún día quieres irte de Mumbler, escríbenos a `,
  faq5Email: ({ email }) => email,
  faq5Info3: ` y coordinaremos contigo el traspaso de información de suscriptores y también configuraremos una redirección de tu feed público para que no pierdas el contacto con ellos por el camino.`,
  faq6: `¿Por qué usar Mumbler en lugar de crear mi propio membership site en WordPress o cualquier otro sistema?`,
  faq6Info: `Crear un membership site para un podcast de pago no es una tarea trivial. Requiere conocimientos técnicos avanzados o una inversión considerable en el desarrollo de un sitio a medida.`,
  faq6Info2: `Mumbler simplifica al máximo el proceso de creación de un podcast privado. Con unos pocos clicks y un par de minutos, puedes tener tu podcast funcionando, `,
  faq6Bold: `sin conocimientos técnicos`,
  faq6Info3: ` ni dolores de cabeza. De este modo puedes centrarte en generar el mejor contenido para tu audiencia.`,
  faq7: `¿Cómo funciona el RSS en Mumbler?`,
  faq7Info: `En Mumbler generamos `,
  faq7Bold: `dos tipos de RSS`,
  faq7Info2: ` para cada podcast:`,
  faq7PointCommon: `Un `,
  faq7PointBold: `RSS público`,
  faq7Point: ` con los episodios gratuitos, que puedes compartir en cualquier podcatcher para ganar más audiencia.`,
  faq7Point2Bold: `RSS privado`,
  faq7Point2: ` para cada suscriptor de tu podcast que contiene los episodios gratuitos y los de pago. Si un suscriptor deja de pagar, el RSS privado deja de funcionar.`,
  faq8: `El hosting para mi podcast está incluído?`,
  faq8Info: `Sí, el hosting de tu podcast está incluído y no tienes que pagar por él.`,
};
es.testimonial = {
  title: 'Ya en Mumbler',
  abel: 'Cuando empiezas a usar Mumbler te das cuenta de que la plataforma ha sido creada por y para podcasters.',
  claudio:
    'Si me preguntas ¿Por qué elegí Mumbler?, la respuesta es sencilla como su interfaz. Me conquistó su transparencia, los deseos de superación de sus creadores y conocer quién está detrás de cada suscripción.',
};

es.creator = {
  creatorPage: 'Página del creador',
  myPodcastsAndAudioSeries: 'Mis podcasts y audioseries',
  emailAddress: 'Correo electrónico',
  name: 'Nombre',
  thisIsWhatOthersHaveToSay: 'Esto es lo que dicen',
  publishCreatorPage: 'Publicar página del creador',
  creatorName: 'Nombre del creador',
  creatorNameLabel: 'Nombre del creador que quieres que se muestre',
  emptyCreatorNameError: 'Por favor, introduce el nombre del creador',
  longCreatorNameError:
    'El nombre del creador no puede tener más de 20 caracteres',
  formCtaTitle: 'Por favor, introduce un título',
  formCtaText: 'Por favor, introduce un texto',
  aboutTitleError: 'Por favor, introduce un título',
  aboutTextError: 'Por favor, introduce un texto',
  domain: 'Dominio',
  domainError: 'Por favor, introduce el dominio',
  domainErrorMessage: 'El dominio debe ser un dominio valido',
  domainLabel: 'Nombre del creador',
  copyDomainURL: 'Copiar URL del dominio',
  formCTA: 'Llamada a la acción del formulario',
  title: 'Título',
  text: 'Texto',
  button: 'Botón',
  aboutSection: 'Sección sobre mi',
  username: 'Nombre de usuario',
  testimonialTweets: 'Tweets testimoniales',
  pasteEmbedTweet: 'Pegar tweet embebido',
  showNewsletter: 'Mostrar newsletter',
  showNewsletterLabel:
    'Mostrar las últimas ediciones de la newsletter en mi página de creador',
  brand: 'Marca',
  btnColor: 'Color del botón',
  txtColor: 'Color del texto',
  creatorCreationTitle: 'Creador ha creado',
  creatorCreationDescription: 'Datos del creador añadidos',
  creatorUpdateTitle: 'Datos del creador actualizados',
  creatorUpdateDescription: 'Datos del creador actualizados',
  subscribedSuccess: 'Suscrito correctamente',
  termsError: 'Por favor, acepta nuestros términos y condiciones',
  ctaTextMaxLimitError: 'Este campo no puede tener más de 200 caracteres',
  aboutTextMaxLimitError: 'Este campo no puede tener más de 500 caracteres',
  donations: 'Donaciones',
  donationLabel: 'Quiero aceptar donaciones en mi página de creador.',
  donateHelpText: 'Cuenta a los visitante por qué donar…',
  helpLabel: 'Help text',
  donationTitle: ({ name }) => `Ayuda a ${name} a seguir creando`,
  cool: 'Genial!',
  confirmYourDonationPlease: 'Confirma la donación por favor.',
  goAhead: 'Aceptar',
  yourMonthlyDonationIsAlreadyActiveForThisCreator:
    'Tu donación mensual ya está activa para este creador. Si deseas cambiarla, por favor ve a Mi Feed → Mis donaciones y para la donación actual.',
  goToMyFeed: 'Ir a Mi Feed',
  thanksForDonating: 'Gracias por apoyar',
  helpWithPrice: ({ price, currency }) => `Aporta ${price}${currency}`,
  helpWithPricePerMonth: ({ price, currency }) =>
    `Aporta ${price}${currency}/mes`,
  puntual: 'Puntual',
  monthly: 'Mensual',
  donationAmountShouldNotBeLessThan1: ' La donación no puede ser menor de 1',
  donationAmountShouldNotBeMoreThan500:
    'La donación no puede ser superior a 500',
  youDoNotHaveAnyActiveMonthlyDonation:
    'No tienes ninguna donación recurrente activa',
  youHaveNotSubscribedToAnyCreator: 'No te has suscrito a ningún creador',
};

es.newsletter = {
  publicationName: 'Nombre de la publicación',
  publicationNameLabel: 'Nombre de tu newsletter',
  emptyPublicationNameError:
    'Por favor, introduce un nombre para la publicación',
  longPublicationNameError:
    'El nombre del creador no puede tener más de 50 caracteres',
  description: 'Descripción corta',
  descriptionLabel: 'Título',
  emptyDescriptionError: 'Por favor, introduce la descripción corta',
  longDescriptionError:
    'La descripción corta no puede tener más de 250 caracteres',
  category: 'Categoría',
  categoryError: 'Por favor, selecciona la categoría',
  select: 'Selecciona',
  noOptionFound: 'No se encontró la opción',
  language: 'Idioma',
  languageError: 'Por favor, selecciona el lenguaje',
  welcomeEmail: 'Email de bienvenida',
  welcomeEmailInfo:
    'Email enviado a los usuarios nada más se convierten en suscriptores gratuitos',
  dontSendWelcomeEmail: `No enviar un email de bienvenida`,
  squareLogo: 'Logo cuadrado',
  squareLogoInfo: 'Debe tener al menos 256x256 píxeles',
  emailSenderName: 'Nombre del remitente',
  emailSender: 'Email del remitente',
  emailSenderEmailInfo:
    'Los destinatarios de tus emails verán esto como el remitente',
  emptySenderNameError: 'Por favor, introduce el nombre del remitente',
  emptySenderEmail: 'Por favor, introduce el email del remitente',
  emailsHeader: 'Cabecera de los emails',
  emptyEmailsHeaderError: 'La cabecera de los emails no puede estar vacía',
  emailsHeaderInfo: 'Cabecera para las newsletters',
  enterTheCopy: 'Introduce el copy',
  emailsFooter: 'Pie de los emails',
  emptyEmailsFooterError: 'El pie de los emails no puede estar vacío',
  emailsFooterInfo: 'Pie para todas las newsletters',
  newsletterCreationTitle: 'Configuración de la newsletter',
  newsletterCreationDescription:
    'La configuración de la newsletter ha sido realizada con éxito',
  newsletterUpdateTitle: 'Configuración de la newsletter',
  newsletterUpdateDescription:
    'La configuración de la newsletter ha sido actualizada con éxito',
  previewNewsletter: 'Preview de la newsletter',
  pleaseSubscribeToProPlan: 'Por favor, suscríbete a un plan PRO',
  contactUsForMoreAudience:
    'Has llegado al límite de audiencia. Por favor, contáctanos.',
  goToPlans: 'Ir a planes',
  contactUs: 'Contáctanos',
  youHaveSuccessfullyUnsubscribed: 'Te has desuscrito correctamente.',
  eventTrackingDataUpdatesInSomeInterval:
    'Aviso: Los eventos no se actualizan inmediatamente.',
  couponOnlyForPro:
    'Los cupones solo están disponibles en los planes PRO. Por favor, suscríbete a un plan PRO para crear nuevos cupones.',
  audioInsertionForPro:
    'Los audio inserciones solo están disponibles en los planes PRO. Por favor, suscríbete a un plan PRO para crear nuevos audio inserciones.',
  deliveries: 'Envíos',
  opens: 'Aperturas',
  ctr: 'CTR',
  newEdition: 'Nueva edición',
  editEdition: 'Editar edición',
  writeYourEdition: 'Escribe tu edición',
  editionDetails: 'Detalles de la edición',
  pleaseEnterTitle: 'Por favor, introduce un título',
  newsletterTitle: 'Título de la newsletter',
  linkURL: 'URL del enlace',
  goToNewsletterSettingPage:
    'La configuración de la newsletter no está definida. Por favor, ve a Settings --> Newsletter',
  successful: 'Exitoso',
  emailSentSuccessfully: 'Email enviado correctamente',
  pleaseEnterTheDescription: 'Por favor, introduce la descripción',
  describeThisNewsletterEdition: 'Describe esta edición de la newsletter',
  sendTestEmail: 'Enviar email de prueba',
  tagsCanNotBeEmpty: 'Las etiquetas no pueden estar vacías',
  includedAudience: 'Audiencia incluída',
  pleaseAddSubjectAndContentForWelcomeEmail:
    'Por favor, añade asunto y contenido a tu email de bienvenida',
  deleteEdition: 'Borrar Edición',
  delete: 'Borrar',
  deleteMessage: 'Esto eliminará el boletín de forma permanente',
  latestEditions: 'Últimas ediciones de la newsletter',
  by: 'de',
  showNewsletterInCreatorPage: 'Mostrar boletín en la página del creador',
  hideNewsletterInCreatorPage: 'Ocultar boletín en la página del creador',
  newsletterVisibilityUpdatedSuccessfully:
    'Visibilidad del boletín actualizada con éxito',
  beforeSendingAnyNewsletterOrEmailYouShouldDefineYourNewsletterSettingsPlease:
    'Antes de enviar una newsletter o un email, debes configurar los ajustes de la newsletter por favor.',
  saveAndGoToSettings: 'Guardar y configurar',
  pleaseEnterSomeNewsletterContent:
    'Por favor ingrese algún contenido del boletín',
  seoOptions: 'SEO Options',
  seoTitle: 'SEO title',
  seoDescription: 'SEO description',
  pleaseEnterSeoTitle: 'Please enter SEO title',
  pleaseEnterSeoDescription: 'Please enter SEO description',
  customButtonText: 'Botón customizable',
  shareButtonText: 'Compartir',
  subscribeButtonText: 'Suscríbete',
  donateButtonText: 'Donar',
  donationButtonTooltip:
    'No puede añadir este botón porque no tienes las donaciones activadas.',
};

es.settings = {
  profile: 'Perfil',
  creatorPagePlan: 'Página de creador / empresa',
  creatorPage: 'Página del creador',
  newsletter: 'Newsletter',
  billing: 'Facturación',
  plans: 'Planes',
  subscribe: ({ price, currency }) =>
    `Suscríbete por ${formatNumber(price)}${currency}/mes + IVA`,
  free: 'GRATIS',
  pro: 'PRO',
  plus: 'PLUS',
  planPrice: ({ price }) => `${price}€/mes`,
  unlimitedAudios: 'Podcasts y audioseries Ilimitados',
  downloadPerMonth: ({ plays }) => `${plays} reproducciones/mes`,
  podcastHosting: 'Alojamiento de podcasts',
  freeAudioProducts: 'Podcasts y audioseries gratuitos',
  paymentAudioProducts: 'Podcasts y audioseries de pago',
  fees20: '20% comisiones',
  audioInsertion: 'Audio Inserción',
  subscribersPlan: ({ subscribers }) => `${subscribers} suscriptores de pago`,
  segmentedEmails: 'Emails segmentados',
  contacts: ({ contact }) => `${contact} contactos de email`,
  sendNewsletters: 'Enviar newsletters',
  donation: 'Donaciones',
  rssFeedsImport: 'Importación de Feeds RSS',
  transcriptions: 'Transcripciones con IA',
  unlimitedDownloads: 'Descargas Ilimitadas',
  stripeFees: 'Comisiones de Stripe',
  promoCodes: 'Códigos promocionales',
  minPerMonthAITranscriptions: ({ min }) => `${min} min/mes transcripciones IA`,
  pauseSubscriptions: 'Parar suscripciones',
  hosting: 'Hosting',
  ultraPlan: `Necesitas más? Pregunta por nuestro plan <a href="mailto:hello@mumbler.io"><b>Ultra</b></a>.`,
  sellPodcastsSuscriptions: 'Vende suscripciones a podcasts',
  sellOnePaymentAudioSeries: 'Vende audioseries de pago único',
  mumblerComissionFeeForFreeUser: 'Si vendes: 10% + 30cts. por venta',
  mumblerComissionFeeForProUser: 'Si vendes: 3% + 30 cts. por venta',
  freePodcasts: 'Aloja podcasts gratuitos',
  freeAudioSeries: 'Aloja Audioseries gratuitas',
  upTo1000Subscribers: 'Hasta 1.000 suscriptores',
  sellDigitalProducts: 'Vende productos digitales',
  payAsYouGrow: 'Paga según crezcan tus contactos',
  creatorPagePublishWarning:
    'La página de creador no está publicada aún. Debes marcar la opción “Publicar página de creador” para publicarla.',
  emailMarketing: 'Email marketing',
  goToPlans: 'Actualizar',
  congratulationsYouHaveReachedTheLimitsOfTheFreePlan:
    '¡Felicidades! 🚀 Has alcanzado los límites de tu plan gratuito.',
  subscribeNowToAPaidPlanToKeepGrowingAndEnjoyAllOurAdvancedFeatures:
    'Suscríbete ahora a un plan de pago para seguir creciendo y disfrutar de todas nuestras funcionalidades avanzadas.',
  subscribeNow: 'Suscríbete ahora',
  congratulationsYouHaveReachedTheLimitsOfTheProPlan:
    '¡Felicidades! 🚀 Has alcanzado los límites del plan PRO.',
  subscribeNowToThePLusPlanToKeepGrowingAndEnjoyAllOurAdvancedFeatures:
    'Suscríbete ahora al plan PLUS para seguir creciendo y disfrutar de todas nuestras funcionalidades avanzadas.',
  congratulationsYouHaveReachedTheLimitsOfThePlusPlan:
    '¡Felicidades! 🚀 Has alcanzado los límites del plan PLUS.',
  contactUsToLearnMoreAboutOurUltraPlan:
    'Contacta con nosotros y te contaremos más sobre nuestro plan ULTRA.',
  contact: 'Contactar',
  warningMessageToShowUserExceedPlanLimits:
    'Tu éxito crece cada vez más y ha llegado el momento de actualizar tu plan.',
  warningMessageToShowUserExceedPlanLimitsInBanner:
    'Has excedido los límites de tu plan actual.',
  pleaseUpgradeYourPlanToAHigherTier:
    'Por favor actualiza tu plan a uno superior.',
  subscriptionEndForOldProPlan: ({ currency, date }) =>
    `Has cancelado la suscripción de 9${currency}/mes. Puedes seguir accediendo a las funcionalidades del Plan Pro hasta ${date}`,
  subscribeTo: ({ name }) => `Suscríbete al plan ${name}`,
  thenPerMonthStartingOn: ({ currency, price, date }) =>
    `Después ${price}${currency} + VAT cada mes empezando en ${date}`,
  remainingTimeOnAfter: ({ plan, date }) =>
    `Tiempo restante del ${plan} después de ${date}`,
  unusedTimeOnAfter: ({ plan, date }) =>
    `Tiempo no usado del ${plan} después de ${date}`,
  proratedThenBilledMonthly: 'Prorateado y después cobrado cada mes',
  pricePerMonthAfter: ({ currency, price }) =>
    `${price}${currency}/mes después`,
  subtotal: 'Subtotal',
  totalExcludingTax: 'Total excluyendo impuestos',
  total: 'Total',
  appliedBalance: 'Balance aplicado',
  totalDueToday: 'Total a pagar',
  billsOnForOneMonth: ({ date }) => `Facturas a ${date} por 1 mes.`,
  downgradePrice: ({ price, currency }) => `${price}${currency} + IVA`,
  downgrade: 'Bajar de plan',
  upgrade: 'Subir de plan',
  subscribeButton: 'Suscribirse',
  successfullyUnsubscribeFromTheCreatorPlan: 'Te has desuscrito correctamente',
};

es.audience = {
  addSubscriber: 'Añadir contactos',
  subscriberAddedSuccessfully: 'El suscriptor se ha añadido correctamente',
  audience: 'Audiencia',
  editASubscriber: 'Editar un suscriptor',
  addCommaSeparatedTags: 'Añadir tags separados por comas',
  updatedRecords: 'Actualizar registros',
  skippedRecords: 'Saltar registros',
  csvWillBeUploadedShortly: 'El CSV se subirá en breves momentos',
  csvImportedSuccessfully: 'CSV importado correctamente',
  howDoYouWantToImport: '¿Cómo lo quieres importar?',
  manually: 'Manualmente',
  csv: 'CSV',
  clickHere: 'Haz click aquí',
  toDownloadSampleCSVFile: 'para descargar un CSV de ejemplo.',
  addSubscriberManually: 'Añadir contacto manualmente',
  importCsv: 'Importar CSV',
  onlyCsvUploadsSupported: 'Solo se permiten ficheros CSV',
  pleaseUploadFile: 'Por favor, sube un fichero',
  deleteSubscriber: 'Eliminar suscriptor',
  deleteSubscriberWarningMessage: ({ email }) =>
    `¿Estás seguro de que quieres borrar este suscriptor? (${email})`,
  deleteSubscriberSuccessMessage: 'Suscriptor correctamente eliminado',
  valuesSeparatorInfo:
    'Por favor, asegúrate de que los valores del CSV están separados por "," o ";"',
  onlyUploadsSupportedType: ({ type }) =>
    `Solo las importaciones con ${type} están soportadas.`,
  theImportProcessMakeTakeAFewMinutes:
    'La importación puede tardar unos minutos.',
};

es.dashboard = {
  requestForPayment: 'Pedir pago',
  yourPaymentHasBeenSuccessfullyInitiated:
    'Tu petición de pago ha sido recibida',
  dashboard: 'Dashboard',
  allContacts: 'Todos los contactos',
  playsViews: 'Reproducciones/visualizaciones',
  customers: 'Compradores',
  sales: 'Ventas',
  subscribers: `Contactos`,
  claimYourAmount: 'Solicita el pago',
  claimYourDonations: 'Solicita el pago de las donaciones',
  lastPayment: 'En camino:',
  youCannotClaimPayoutUntil50: 'No puedes solicitar el pago hasta los 50',
  youCanClaimOnlyIfTheAmountIsMoreThan50:
    'Sólo puedes solicitar el pago si la cantidad es superior a 50',
  requestForPayout: 'Solicitar pago',
  contacts: 'Contactos',
  donations: 'Donaciones',
};

es.feed = {
  myFeed: 'Mi feed',
  feed: 'Feed',
  myPurchases: 'Mis compras',
  myCreators: 'Mis creadores',
  myDonations: 'Mis donaciones',
  createYourOwnAudienceOnMumbler: 'Crea tu propia audiencia en Mumbler',
  createYourAudienceSendNewslettersAndSellYourDigitalProductsAsEasyAsItGets:
    'Crea tu audiencia, envía emails y vende tus productos digitales. Tan fácil como parece.',
  startNow: 'Empieza ahora',
  viewMore: 'Ver más',
  stopDonating: 'Parar la donación',
  areYouSureYouWantToStopDonatingNow:
    'Estás seguro/a? Quieres dejar de apoyar?',
  cancelSubscription: 'Cancelar suscripción   ',
};

es.startWizard = {
  welcomeToMumbler: 'Hola!',
  inOrderToOfferYouTheBestExperienceCanYouTellUsWhyDidYouSignUpForMumbler:
    'Nos alegra verte por aquí! Por qué te diste de alta en Mumbler? (usaremos esta información para ofrecerte la mejor experiencia).',
  enjoyContent: 'Disfrutar los contenidos',
  publishContentAndCreateAnAudience:
    'Publicar contenidos y/o crear una audiencia',
  justLooking: 'Solo estoy mirando',
  claimYourCreatorPage: 'Reclama tu página de creador',
  chooseYourNameAndClaimYourCreatorPageInMumblerToGrowAnAudienceAndSellYourAudios:
    'Elige un nombre y reclama tu página de creador para hacer crecer una audiencia y vender tus audios.',
  aLittleBitAboutYourself: 'Un poco sobre ti',
  allowPeopleToDiscoverMoreAboutYourselfSoTheyWantToSubscribeToYourContents:
    'Cuéntale a la gente un poco sobre ti, para animarles a suscribirse a tus contenidos.',
  hurray: 'Bien!',
  yourCreatorPageIsUpAndRunning: 'Tu página de creador está ya en marcha.',
  shareYourPage: 'Comparte tu página',
  goToCreatorPageSettings: 'Accede a los ajustes de la página',
  followYourTopCreators: 'Sigue a tus creadores favoritos',
  onMumblerYouCanSubscribeToThePremiumContentAndNewslettersOfYourFavouriteCreators:
    'En Mumbler te puedes suscribir al contenido de pago y a las newsletters de tus creadores favoritos.',
  inThisSectionMyFeedYouWillHaveDirectAccessToTheirLatestContents:
    'En esta sección “Mi feed”, tendrás acceso directo a sus últimos contenidos.',
  discoverNewContentAandCreators: 'Descubre nuevo contenido y creadores',
  wantToDiscoverNewCreatorsAnAmazingContents:
    'Quieres descubrir nuevos creadores y contenidos espectaculares?',
  weGotYouCoveredVisitOurDiscoverPage:
    'Lo tenemos controlado. Visita la página descubrir.',
  becomeACreator: 'Conviértete en creador!',
  weTrulyBelieveEveryoneIsACreatorYouToo: `Nosotros creeemos que todo el mundo es un <b>creador</b>. Tú también.`,
  youCanOpenYourCreatorPageWheneverYouWantByVisitingYourSettingsPage:
    'Puedes abrir tu página de creador, siempre que quieras, visitando la página de configuración.',
  lifeIsBetterWhenYouSetUpYourMumblerAccount:
    'La vida es más bonita con la cuenta de Mumbler configurada',
  setUpYourCreatorPage: 'Configura tu página de creador',
  setUpYourNewsletter: 'Configura tu newsletter',
  importYourEmailDatabase: 'Importa tu base de datos de emails',
  createYourFirstProduct: 'Crea tu primer producto',
};

es.donation = {
  donations: 'Donaciones',
  donate: ({ podcastPrice, currency }) =>
    `Donar ${formatNumber(podcastPrice)}${currency}`,
  donatePerMonth: ({ podcastPrice, currency }) =>
    `Donar ${formatNumber(podcastPrice)}${currency}/mes`,
};

export default es;