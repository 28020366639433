import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import UserContext from 'context/UserContext';
import useLang from 'context/LanguageContext';
import { translate as t } from 'utils/helpers';
import styles from './Popup.module.scss';

const PlanLimitExceedPopup = () => {
  const { lang } = useLang();
  const [userData, setUserData] = useContext(UserContext);
  const hideModal = () => {
    if (userData?.planLimitPopup?.closeActionCallback) {
      userData?.planLimitPopup?.closeActionCallback();
    }
    setUserData((prevState) => ({
      ...prevState,
      planLimitPopup: { showModal: false },
    }));
  };

  return (
    <Modal
      className={styles.modalParent}
      show={userData?.planLimitPopup?.showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={hideModal}>
      <Modal.Body className={styles.modalBody}>
        <h4 className={styles.modalTitle}>{userData?.planLimitPopup?.title}</h4>
        {userData?.planLimitPopup?.planLimitExceedName === 'Free Plan' && (
          <>
            <p
              className={styles.modalDescription}
              style={{ whiteSpace: 'pre-line' }}>
              {t(
                lang,
                'settings',
              )('congratulationsYouHaveReachedTheLimitsOfTheFreePlan')}
            </p>
            <p
              className={styles.modalDescription}
              style={{ whiteSpace: 'pre-line' }}>
              {t(
                lang,
                'settings',
              )(
                'subscribeNowToAPaidPlanToKeepGrowingAndEnjoyAllOurAdvancedFeatures',
              )}
            </p>
          </>
        )}
        {userData?.planLimitPopup?.planLimitExceedName === 'PRO' && (
          <>
            <p
              className={styles.modalDescription}
              style={{ whiteSpace: 'pre-line' }}>
              {t(
                lang,
                'settings',
              )('congratulationsYouHaveReachedTheLimitsOfTheProPlan')}
            </p>
            <p
              className={styles.modalDescription}
              style={{ whiteSpace: 'pre-line' }}>
              {t(
                lang,
                'settings',
              )(
                'subscribeNowToThePLusPlanToKeepGrowingAndEnjoyAllOurAdvancedFeatures',
              )}
            </p>
          </>
        )}
        {userData?.planLimitPopup?.planLimitExceedName === 'PLUS' && (
          <>
            <p
              className={styles.modalDescription}
              style={{ whiteSpace: 'pre-line' }}>
              {t(
                lang,
                'settings',
              )('congratulationsYouHaveReachedTheLimitsOfThePlusPlan')}
            </p>
            <p
              className={styles.modalDescription}
              style={{ whiteSpace: 'pre-line' }}>
              {t(lang, 'settings')('contactUsToLearnMoreAboutOurUltraPlan')}
            </p>
          </>
        )}
        <div className={styles.confirmCTAs}>
          {!userData?.planLimitPopup?.hideCancelButton && (
            <Button
              className={`${styles.popupBtn} compact mb-3`}
              variant="outline-primary"
              onClick={hideModal}>
              {t(lang, 'common')('close')}
            </Button>
          )}
          {userData?.planLimitPopup?.showSubmitButton && (
            <Button
              className={`${styles.popupBtn} compact mb-3`}
              onClick={() => {
                hideModal();
                userData?.planLimitPopup?.confirmActionCallback();
              }}
              style={{
                backgroundColor: userData?.planLimitPopup?.submitButtonColor,
                color: userData?.planLimitPopup?.submitButtonColor && '#fff',
                borderColor: userData?.planLimitPopup?.submitButtonColor,
              }}>
              {userData?.planLimitPopup?.planLimitExceedName === 'PLUS'
                ? t(lang, 'settings')('contact')
                : t(lang, 'settings')('subscribeNow')}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PlanLimitExceedPopup;
